export async function waitFor<T>(func: () => Promise<T>, delay: number): Promise<T> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      func()
        .then((res: T) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    }, delay);
  });
}
