import React from 'react';
import { BaseModal } from './BaseModal';
import { observer } from 'mobx-react-lite';

interface Props {
  className?: string;

  /**
   * Modal title
   */
  title?: string;
  /**
   * action label
   */
  actionLabel?: string;
  /**
   * Callback for when the operation is cancelled or the modal is closed
   */
  onDismiss?: () => void;
  /**
   * Defines what width the modal will have
   */
  size?: 'sm' | 'lg' | 'xl';
}

export const Alert: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ className, title, onDismiss, size, children, actionLabel = 'Okay' }) => (
    <BaseModal
      title={title}
      label
      size={size}
      theme="confirm"
      onDismiss={onDismiss}
      className={className}
      footerContent={
        <button className="btn primary" type="button" onClick={onDismiss}>
          {actionLabel}
        </button>
      }
    >
      {children}
    </BaseModal>
  )
);
