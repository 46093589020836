import styles from './AppMainNav.module.scss';
import { ModalType, useSiteStore } from '../../../store/site';
import { useUserStore } from '../../../store/user';
import Link from 'next/link';
import { ChevronDownIcon } from '@radix-ui/react-icons';

interface Props {
  showJoinNetwork: boolean;
  onClickJoinNetwork: () => void;
}

export const BaseNav = ({ showJoinNetwork, onClickJoinNetwork }: Props) => {
  const user = useUserStore();
  const site = useSiteStore();

  function Nav() {
    if (!user.isAuthenticated()) {
      return (
        <>
          <li className={styles.base}>
            <Link
              href="/login"
              onClick={(event) => {
                event.preventDefault();
                site.showModal(ModalType.Login);
              }}
            >
              Log In
            </Link>
          </li>
          <li className={styles.base}>
            <Link
              href="/register"
              onClick={(event) => {
                event.preventDefault();
                site.showModal(ModalType.Signup);
              }}
            >
              Sign Up
            </Link>
          </li>
          <li className={styles.base}>
            <button
              aria-expanded={showJoinNetwork}
              aria-controls="join-options"
              aria-haspopup="true"
              aria-label="site menu"
              className={styles.joinNetworkToggle}
              onClick={onClickJoinNetwork}
              type="button"
              data-testid="join-network-menu"
            >
              Join Our Network
              <ChevronDownIcon />
            </button>
          </li>
        </>
      );
    }

    return (
      <li className={styles.base}>
        <Link href="/logout" onClick={user.logout}>
          Log out
        </Link>
      </li>
    );
  }

  return (
    <>
      <Nav />
      <li className={styles.base}>
        <Link href="/refer">Refer</Link>
      </li>
    </>
  );
};
