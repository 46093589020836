import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import styles from './CommonModalFooter.module.scss';

type CommonModalFooterProps = {
  children: React.ReactNode;
  className?: string;
};

export const CommonModalFooter = observer(({ children, className }: CommonModalFooterProps) => (
  <footer className={classNames(styles.footerButtonRow, className)}>{children}</footer>
));

CommonModalFooter.displayName = 'CommonModalFooter';
