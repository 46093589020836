import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useSiteStore } from '../../../../../store/site';
import { useUserStore } from '../../../../../store/user';
import { AuthStep, AuthStore } from './authStore';
import { EmailAuthStep } from './steps/EmailAuthStep';
import { LoginAuthStep } from './steps/LoginAuthStep';
import { SignUpAuthStep } from './steps/SignUpAuthStep';
import { ActivationAuthStep } from './steps/ActivationAuthStep';
import { Spinner } from '../../../../atoms/Spinner';
import { ProfileAuthStep } from './steps/ProfileAuthStep';

interface Props {
  onLogin?: () => void;
  onDismiss: () => void;
}

export const LoginOrSignUpModal: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ onLogin, onDismiss }) => {
    const [auth, setAuth] = useState<AuthStore | null>(globalThis.Rose.auth || null);
    const user = useUserStore();
    const site = useSiteStore();

    useEffect(() => {
      if (!window.Rose.auth) {
        const store = new AuthStore(
          user,
          site,
          () => {
            window.Rose.auth = null;
            onDismiss();
          },
          onLogin
        );
        window.Rose.auth = store;
        setAuth(store);
      }
      if (!auth) setAuth(window.Rose.auth);
    }, [auth, onDismiss, onLogin, site, user]);

    if (!auth) {
      return null;
    }

    // eslint-disable-next-line default-case -- missing case will cause a compile error
    switch (auth.currentStep) {
      case AuthStep.Email:
        return <EmailAuthStep auth={auth} />;
      case AuthStep.Login:
        return <LoginAuthStep auth={auth} />;
      case AuthStep.Social:
        return (
          <div>
            <p>Waiting for login...</p>
            <Spinner />
          </div>
        );
      case AuthStep.Signup:
        return <SignUpAuthStep auth={auth} />;
      case AuthStep.Activation:
        return <ActivationAuthStep auth={auth} />;
      case AuthStep.Profile:
        return <ProfileAuthStep auth={auth} />;
    }
  }
);

LoginOrSignUpModal.displayName = 'LoginOrSignUpModal';
