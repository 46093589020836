import classNames from 'classnames';

import { AutocompletePrediction } from '../../hooks/useAutocompleteAddress';

import styles from './AddressAutocompleteSuggestions.module.scss';

type AddressAutocompleteSuggestionsProps = {
  data: AutocompletePrediction[];
  handleSelectedAddress: (address: AutocompletePrediction) => void;
};

export const AddressAutocompleteSuggestions = ({
  data,
  handleSelectedAddress,
}: AddressAutocompleteSuggestionsProps) =>
  data.length ? (
    <ul className={classNames('reset-list', styles.resultsList)}>
      {data.map((suggestion) => {
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          place_id,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <li key={place_id}>
            <button
              className={classNames('ghost-button', styles.resultsListItem)}
              onClick={() => handleSelectedAddress(suggestion)}
              type="button"
            >
              <strong>{main_text}</strong> <small>{secondary_text}</small>
            </button>
          </li>
        );
      })}
    </ul>
  ) : null;

AddressAutocompleteSuggestions.displayName = 'AddressAutocompleteSuggestions';
