import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { IUserInfo } from '../../../model/UserInfo';

import { NavigationRegex } from '../../../util/constants';

import styles from './UserNavProvider.module.scss';

type UserProfileNavigationProps = {
  onClickCallback: (showSidebar: boolean) => void;
  user: IUserInfo;
};

export const UserNavProvider = observer(({ onClickCallback, user }: UserProfileNavigationProps) => {
  const router = useRouter();

  const leadsMatch = NavigationRegex.LEADS.test(router.pathname);
  const jobsMatch = NavigationRegex.JOBS.test(router.pathname);

  const providerLinks = [
    {
      isActive: leadsMatch,
      href: `/users/${user.id}/leads`,
      label: 'Qualified leads',
    },
    {
      isActive: jobsMatch,
      href: `/users/${user.id}/jobs`,
      label: 'My jobs',
    },
  ];

  return (
    <>
      {providerLinks.map((entry) => (
        <li className={classNames(entry.isActive && styles.active)} key={entry.label} role="none">
          <Link onClick={() => onClickCallback(false)} href={entry.href}>
            {entry.label}
          </Link>
        </li>
      ))}
    </>
  );
});

UserNavProvider.displayName = 'UserNavProvider';
