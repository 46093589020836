import React, { HTMLAttributes } from 'react';
import { IFile, PendingUploadFile, UploadedFile } from '../../types/Field';

interface Props extends HTMLAttributes<any> {
  /**
   * File to be shown
   */
  file: IFile;
  /**
   * Alt text for image file
   */
  altText?: string;

  controls?: boolean;

  className?: string;
}

export const FilePreview: React.FC<Props> = ({
  className,
  file,
  altText,
  controls = true,
  ...rest
}) => {
  const mimeType = file.getMimeType();
  if (!file.isImage && !file.isVideo && !file.isPdf) {
    if (!mimeType) {
      return <span>Missing or invalid mime type can't be displayed</span>;
    }
    return <span>{mimeType} not supported</span>;
  }

  // Added this function here as the file.url always returns undefined.
  // There is a getter function for url in `PendingUploadFile` but even
  // though `file instanceof PendingUploadFile` returns true, the getter
  // is not called.
  function getURL(previewFile: PendingUploadFile): string {
    // if the file already has a url, return it
    if (previewFile.url) {
      return previewFile.url;
    }

    if (previewFile.objectUrl) {
      return previewFile.objectUrl;
    }

    previewFile.objectUrl = URL.createObjectURL(previewFile.fileData);
    return previewFile.objectUrl;
  }

  const previewURL = getURL(file as PendingUploadFile);

  if (file.isVideo) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        className={className}
        controls={controls}
        aria-label={file.description || 'uploaded video'}
        {...rest}
      >
        <source src={previewURL} type="video/mp4" />
      </video>
    );
  }

  if (file.isPdf) {
    return (
      <object
        className={className}
        aria-label={file.description || 'uploaded pdf'}
        data={previewURL}
        {...rest}
      />
    );
  }

  return <img className={className} src={previewURL} alt={altText} {...rest} />;
};

FilePreview.displayName = 'FilePreview';
