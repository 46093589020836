import React from 'react';
import { IProviderInfo } from '../../../../../model/UserInfo';

import { CommonModal } from '../../common/CommonModal';
import { CommonModalHeader } from '../../common/CommonModalHeader';
import { CommonModalFooter } from '../../common/CommonModalFooter';

import styles from './SuccessfullyCreatedProposalModal.module.scss';

export interface SuccessfullyCreatedProposalModalProps {
  /**
   * Handler for when the operation is cancelled or the modal is closed
   */
  onDismiss: () => void;
  /**
   * Information about the provider to whom the client is sending the proposal
   */
  providerInfo?: IProviderInfo;
}

export const SuccessfullyCreatedProposalModal: React.FC<
  React.PropsWithChildren<SuccessfullyCreatedProposalModalProps>
> = ({ onDismiss, providerInfo }) => (
  <CommonModal onDismiss={onDismiss}>
    <CommonModalHeader title="Job request created" />
    <div className={styles.success}>
      {providerInfo ? (
        <>
          <h2
            className={styles.successTitle}
          >{`${providerInfo.companyName} has received your job request and will be in touch soon!`}</h2>
        </>
      ) : (
        <p className={styles.proposalSuccessMessage}>
          Our network of providers in your area will be in touch soon!
        </p>
      )}
    </div>
    <CommonModalFooter>
      <button type="button" className="btn red wide extra-rounded" onClick={onDismiss}>
        Done
      </button>
    </CommonModalFooter>
  </CommonModal>
);
