/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import classNames from 'classnames';

import { Checkbox } from '../../../../atoms/Checkbox';
import { TrashIcon } from '../../../../../images/TrashIcon';
import { Input } from '../../../../atoms/Input';
import { AddCircledIcon } from '../../../../../images/AddCircledIcon';
import { ISelectOption } from '../../../../../model/helper';

import styles from './ProposalCustomOption.module.scss';

type Props = {
  option?: ISelectOption;
  onAdd: (value: string) => void;
  onRemove: (value: string) => void;
};

export const ProposalCustomOption: React.FC<React.PropsWithChildren<Props>> = ({
  option,
  onRemove,
  onAdd,
}) => {
  const [addValue, setAddValue] = useState<string>('');
  const onAddKey: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      onAdd(addValue);
    }
  };

  const onAddChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setAddValue(e.target.value);
  };

  if (!option?.value) {
    return (
      <div className={styles.add}>
        <Input
          onKeyDown={onAddKey}
          onChange={onAddChange}
          placeholder="Enter a modification"
          minLength={3}
        />
        <button
          aria-label="add"
          type="button"
          onClick={() => onAdd(addValue)}
          disabled={addValue.length < 3}
          className={classNames('btn icon', styles.customOptionBtn)}
        >
          <AddCircledIcon />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.customOptionContainer}>
      <label>
        <Checkbox checked onClick={() => onRemove(option.value)} /> {option.label}
      </label>
      <button
        aria-label="Remove option"
        className={classNames('ghost-button', styles.customOptionBtn)}
        type="button"
        onClick={() => onRemove(option.label)}
      >
        <TrashIcon className={styles.deleteIcon} />
      </button>
    </div>
  );
};
