import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function AlertIcon({ className, height = '25', width = '25' }: Props) {
  return (
    <AccessibleIcon label="alert">
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 27 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.1862 26.7857H3.65878H1.50604C1.12138 26.7857 0.766053 26.5815 0.573844 26.25C0.381635 25.9185 0.381635 25.51 0.573844 25.1786C0.766053 24.8471 1.1214 24.6429 1.50604 24.6429H2.58241V12.8571C2.58529 10.3005 3.60687 7.84941 5.42304 6.04163C7.23891 4.23386 9.7014 3.21692 12.27 3.21404H14.4227C16.9911 3.21691 19.4536 4.2338 21.2697 6.04163C23.0858 7.84917 24.1074 10.3004 24.1103 12.8571V24.6429H25.1867C25.5713 24.6429 25.9267 24.8471 26.1189 25.1786C26.3111 25.5101 26.3111 25.9185 26.1189 26.25C25.9267 26.5815 25.5713 26.7857 25.1867 26.7857H25.1862ZM15.4988 0H11.1934C10.8087 0 10.4534 0.204239 10.2612 0.535715C10.069 0.86719 10.069 1.27567 10.2612 1.60714C10.4534 1.93861 10.8087 2.14286 11.1934 2.14286H15.4988C15.8835 2.14286 16.2388 1.93862 16.431 1.60714C16.6233 1.27567 16.6233 0.867184 16.431 0.535715C16.2388 0.204245 15.8835 0 15.4988 0ZM8.5455 27.8572C8.92127 28.5096 9.4638 29.0513 10.1177 29.4277C10.772 29.8039 11.5144 30.0014 12.2697 30H14.4225C15.1778 30.0014 15.9203 29.8039 16.5745 29.4277C17.2285 29.0513 17.771 28.5096 18.1467 27.8572H8.5455Z"
          fill="currentColor"
        />
      </svg>
    </AccessibleIcon>
  );
}
