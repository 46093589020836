import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
  label?: string;
  height?: string;
  width?: string;
};

export function KebabIcon({ className, height = '20', label = 'Menu icon', width = '5' }: Props) {
  return (
    <AccessibleIcon label={label}>
      <svg
        className={className}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.62 7.617A2.384 2.384 0 0 0 .237 9.998 2.384 2.384 0 0 0 2.62 12.38 2.384 2.384 0 0 0 5 10a2.384 2.384 0 0 0-2.38-2.382ZM2.62 15.238A2.384 2.384 0 0 0 .237 17.62 2.384 2.384 0 0 0 2.62 20 2.384 2.384 0 0 0 5 17.62a2.384 2.384 0 0 0-2.38-2.382ZM2.62 4.762A2.384 2.384 0 0 0 5 2.38 2.384 2.384 0 0 0 2.62 0 2.384 2.384 0 0 0 .237 2.381 2.384 2.384 0 0 0 2.62 4.76Z"
          fill="currentColor"
        />
      </svg>
    </AccessibleIcon>
  );
}
