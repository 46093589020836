/* eslint-disable max-classes-per-file */
import {
  IClientInfo,
  IProviderInfo,
  IUserInfo,
  ProfileLinkType,
  UserRole,
  IAddressKeys,
} from '../../model/UserInfo';
import {
  CheckboxField,
  Field,
  HoursField,
  ImageField,
  ListField,
  SelectField,
  ServicesField,
  TextFieldType,
  TextLikeField,
  UploadedFile,
} from '../../types/Field';
import { ModificationServiceName, ModificationServices } from '../ServiceConstants';
import {
  DayScheduleValidator,
  ImageFieldValidator,
  MultipleImageFieldValidator,
  RequiredSelectFieldValidator,
  TextFieldValidator,
  URLFieldValidator,
} from './FieldValidators';

import { getUSStates, rangeForSelect } from '../helper';
import { ISelectOption } from '../../model/helper';

export class UserFields {
  public userInfo?: IUserInfo | null;

  public emailField: Field;

  public passwordField: Field;

  public firstNameField: Field;

  public lastNameField: Field;

  public profilePictureField: ImageField;

  public backgroundPictureField: Field;

  public zipCodeField: Field;

  public phoneField: Field;

  public loginFields: Field[];

  public addressFields: Field[];

  public contactDetailsFields: Field[];

  public nameFields: Field[];

  public changePasswordFields: Field[];

  public resetPasswordFields: Field[];

  constructor(userInfo?: IUserInfo | null) {
    this.userInfo = userInfo;

    this.emailField = new TextLikeField(
      {
        id: 'email',
        name: 'email',
        label: 'Email Address:',
        placeholder: 'Enter email address',
        value: this.userInfo?.email || '',
      },
      TextFieldType.Email
    );

    this.passwordField = new TextLikeField(
      {
        id: 'password',
        name: 'password',
        label: 'Password:',
        placeholder: 'Enter password',
        value: '',
      },
      TextFieldType.Password
    );

    this.firstNameField = new TextLikeField(
      {
        id: 'firstName',
        name: 'firstName',
        placeholder:
          this.userInfo?.role === UserRole.Provider ? 'Enter owner first name' : 'Enter first name',
        validators: [new TextFieldValidator('first name', { min: 2, max: 30 })],
        value: this.userInfo?.firstName || '',
        label: this.userInfo?.role === UserRole.Provider ? 'Owner first name' : 'First name',
      },
      TextFieldType.Text
    );

    this.lastNameField = new TextLikeField(
      {
        id: 'lastName',
        name: 'lastName',
        placeholder:
          this.userInfo?.role === UserRole.Provider ? 'Enter owner last name' : 'Enter last name',
        validators: [new TextFieldValidator('last name', { min: 2, max: 30 })],
        value: this.userInfo?.lastName || '',
        label: this.userInfo?.role === UserRole.Provider ? 'Owner last name' : 'Last name',
      },
      TextFieldType.Text
    );

    this.zipCodeField = new TextLikeField(
      {
        id: 'zipCode',
        inputMode: 'numeric',
        name: 'zipCode',
        placeholder: 'Enter ZIP code',
        value: this.userInfo?.address?.zipCode || '',
        label: 'ZIP code',
      },
      TextFieldType.Text
    );

    this.phoneField = new TextLikeField(
      {
        id: 'phone',
        name: 'phone',
        placeholder: 'Enter phone number',
        value: this.userInfo?.phone || '',
        validators: [new TextFieldValidator('phone', { min: 10, max: 12 })],
        label: 'Phone number',
      },
      TextFieldType.Text
    );

    this.loginFields = [this.emailField, this.passwordField];

    this.nameFields = [this.firstNameField, this.lastNameField];

    this.addressFields = [
      new TextLikeField(
        {
          id: 'streetAddress',
          name: 'streetAddress',
          placeholder: 'Enter street address',
          validators: [new TextFieldValidator('street address', { min: 2, max: 100 })],
          value: this.userInfo?.address?.streetAddress || '',
          label: 'Street Address',
        },
        TextFieldType.Text
      ),
      new TextLikeField(
        {
          id: 'aptSuiteNumber',
          name: 'aptSuiteNumber',
          placeholder: 'Apartment or suite number',
          value: this.userInfo?.address?.aptSuiteNumber || '',
          label: 'Apartment or Suite',
        },
        TextFieldType.Text
      ),
      new SelectField(
        {
          id: 'state',
          name: 'state',
          label: 'Choose state',
          value: this.userInfo?.address?.state || '',
          validators: [new RequiredSelectFieldValidator('state')],
        },
        getUSStates() as ISelectOption[]
      ),
      new TextLikeField(
        {
          id: 'city',
          name: 'city',
          placeholder: 'Enter city',
          validators: [new TextFieldValidator('city', { min: 2, max: 50 })],
          value: this.userInfo?.address?.city || '',
          label: 'City',
        },
        TextFieldType.Text
      ),
      this.zipCodeField,
    ];

    this.contactDetailsFields = [
      ...this.addressFields,
      this.phoneField,
      new CheckboxField(
        {
          id: 'canCall',
          name: 'canCall',
          value: this.userInfo?.canCall,
        },
        'Call'
      ),
      new CheckboxField(
        {
          id: 'canText',
          name: 'canText',
          value: this.userInfo?.canText,
        },
        'Text'
      ),
    ];

    if (this.userInfo?.role === UserRole.Provider) {
      this.contactDetailsFields = [
        ...this.contactDetailsFields,
        new TextLikeField(
          {
            id: ProfileLinkType.OwnWebsite,
            name: ProfileLinkType.OwnWebsite,
            value: this.getProfileLink(ProfileLinkType.OwnWebsite),
            placeholder: 'Add link',
            label: 'Company Webpage Link',
            validators: [new URLFieldValidator(ProfileLinkType.OwnWebsite, false)],
          },
          TextFieldType.Text
        ),
        new TextLikeField(
          {
            id: ProfileLinkType.Facebook,
            name: ProfileLinkType.Facebook,
            value: this.getProfileLink(ProfileLinkType.Facebook),
            placeholder: 'Add link',
            label: 'Facebook Link',
            validators: [new URLFieldValidator(ProfileLinkType.Facebook, false)],
          },
          TextFieldType.Text
        ),
        new TextLikeField(
          {
            id: ProfileLinkType.Yelp,
            name: ProfileLinkType.Yelp,
            value: this.getProfileLink(ProfileLinkType.Yelp),
            placeholder: 'Add link',
            label: 'Yelp Link',
            validators: [new URLFieldValidator(ProfileLinkType.Yelp, false)],
          },
          TextFieldType.Text
        ),
        new TextLikeField(
          {
            id: ProfileLinkType.Instagram,
            name: ProfileLinkType.Instagram,
            value: this.getProfileLink(ProfileLinkType.Instagram),
            placeholder: 'Add link',
            label: 'Instagram Link',
            validators: [new URLFieldValidator(ProfileLinkType.Instagram, false)],
          },
          TextFieldType.Text
        ),
      ];
    }

    this.changePasswordFields = [
      new TextLikeField(
        {
          id: 'currentPassword',
          name: 'currentPassword',
          placeholder: 'Enter current password',
          value: '',
          label: 'Current password',
        },
        TextFieldType.Password
      ),
      new TextLikeField(
        {
          id: 'password',
          name: 'password',
          placeholder: 'Enter new password',
          validators: [new TextFieldValidator('password', { min: 8, max: 20 })],
          value: '',
          label: 'New password',
        },
        TextFieldType.Password
      ),
    ];

    this.resetPasswordFields = [
      new TextLikeField(
        {
          id: 'password',
          name: 'password',
          placeholder: 'Enter new password',
          validators: [new TextFieldValidator('password', { min: 8, max: 20 })],
          value: '',
          label: 'New password',
        },
        TextFieldType.Password
      ),
    ];

    const profilePictureButtonLabel =
      this.userInfo?.role === UserRole.Provider ? 'Upload Now' : 'Upload New';
    const profilePictureLabel =
      this.userInfo?.role === UserRole.Provider ? 'Upload logo' : 'Choose image file';

    this.profilePictureField = new ImageField(
      {
        id: 'profilePicture',
        name: 'profilePicture',
        label: profilePictureLabel,
        validators: [new ImageFieldValidator(5 /** Maximum 5 Mb in size */)],
        isInline: true,
        value: [
          new UploadedFile(
            'uploaded',
            this.userInfo?.profilePicture
              ? this.userInfo.profilePicture
              : '/static/unknown-client-gray.svg'
          ),
        ],
      },
      false, // multiple
      false, // allowsVideo
      profilePictureButtonLabel // buttonText
    );

    this.backgroundPictureField = new ImageField(
      {
        id: 'backgroundPicture',
        name: 'backgroundPicture',
        label: 'Choose image file',
        validators: [new ImageFieldValidator(5 /** Maximum 5 Mb in size */)],
        isInline: true,
      },
      false, // multiple
      false, // allowsVideo
      'Click to upload new background picture' // buttonText
    );
  }

  get userAddressFields() {
    return this.addressFields.map((field) => field.name);
  }

  private getProfileLink(linkType: ProfileLinkType) {
    return (this.userInfo as IProviderInfo)?.profileLinks?.find(
      (profileLink) => profileLink.service === linkType
    )?.link;
  }
}

export class ClientFields {
  public userInfo?: IClientInfo;

  public birthYearField: SelectField;

  public signupFields: Field[];

  public editProfileFields: Field[];

  constructor(userInfo?: IClientInfo) {
    this.userInfo = userInfo;

    const userFields = new UserFields(userInfo);

    this.signupFields = [
      ...userFields.nameFields,
      new SelectField(
        {
          id: 'birthYear',
          name: 'birthYear',
          label: 'Birth Year',
          value: '',
        },
        rangeForSelect(
          new Date().getFullYear(),
          new Date().getFullYear() - 125,
          -1
        ) as ISelectOption[]
      ),
      userFields.zipCodeField,
    ];

    this.birthYearField = new SelectField(
      {
        id: 'birthYear',
        name: 'birthYear',
        label: 'Birth Year',
        value: `${this.userInfo?.birthYear || ''}`,
      },
      rangeForSelect(
        new Date().getFullYear(),
        new Date().getFullYear() - 125,
        -1
      ) as ISelectOption[]
    );


    this.editProfileFields = [
      userFields.firstNameField,
      userFields.lastNameField,
      this.birthYearField,
      ...userFields.addressFields,
      userFields.emailField,
      ...userFields.contactDetailsFields,
      userFields.profilePictureField,
    ];
  }
}

export class ManagedClientFields {
  public userInfo?: IClientInfo;

  public signupFields: Field[];

  constructor(userInfo?: IClientInfo) {
    this.userInfo = userInfo;

    const userFields = new UserFields(userInfo);

    this.signupFields = [
      ...userFields.nameFields,
      new SelectField(
        {
          id: 'birthYear',
          name: 'birthYear',
          label: 'Birth Year',
          value: '',
        },
        rangeForSelect(
          new Date().getFullYear(),
          new Date().getFullYear() - 125,
          -1
        ) as ISelectOption[]
      ),
      new TextLikeField(
        {
          id: 'email',
          name: 'email',
          placeholder: 'Enter email address',
          validators: [
            new TextFieldValidator('email address', { min: 5, max: 100 }, /\S+@\S+\.\S+/),
          ],
          value: this.userInfo?.email || '',
          label: 'Email',
        },
        TextFieldType.Text
      ),
      userFields.phoneField,
      userFields.zipCodeField,
    ];
  }
}

export class ProviderFields {
  public userInfo?: IProviderInfo;

  public servicesField: Field;

  public serviceAreaField: Field;

  public companyOverviewField: Field;

  public certificationsField: Field;

  public workingHoursField: Field;

  public featuredWorkField: Field;

  public companyNameField: Field;

  public editProfileFields: Field[];

  public standAloneCouponCodeField: Field;

  constructor(userInfo?: IProviderInfo) {
    this.userInfo = userInfo;
    const userFields = new UserFields(userInfo);

    this.companyNameField = new TextLikeField(
      {
        id: 'companyName',
        name: 'companyName',
        placeholder: 'Enter company name',
        validators: [new TextFieldValidator('company name', { min: 2, max: 100 })],
        value: this.userInfo?.companyName || '',
        label: 'Company name',
      },
      TextFieldType.Text
    );

    this.standAloneCouponCodeField = new TextLikeField(
      {
        id: 'coupon',
        name: 'coupon',
        placeholder: 'Coupon code',
        value: '',
        label: 'Apply Coupon Code',
      },
      TextFieldType.Text
    );

    this.servicesField = new ServicesField(
      {
        id: 'services',
        name: 'services',
        label: 'Services offered',
        value: this.userInfo?.services,
      },
      ModificationServices, // defaultOptions
      true, // hasCustomOptions
      this.userInfo?.services?.filter(
        (field) =>
          !ModificationServices.map((service) => service.value).includes(
            field.value as ModificationServiceName
          )
      ) || [], // customOptions
      'Add service' // customOptionPrompt
    );

    this.serviceAreaField = new SelectField(
      {
        id: 'serviceAreaDistance',
        name: 'serviceAreaDistance',
        label: 'Service Area',
        smallText: 'Radius (in miles) from company address',
        value: this.userInfo?.serviceAreaDistance || '30',
      },
      [
        { label: '10 miles', value: '10' },
        { label: '20 miles', value: '20' },
        { label: '30 miles', value: '30' },
        { label: '30+ miles', value: '200' },
      ]
    );

    this.companyOverviewField = new TextLikeField(
      {
        id: 'companyOverview',
        name: 'companyOverview',
        validators: [new TextFieldValidator('company overview', { min: 10, max: 300 })],
        value: this.userInfo?.companyOverview,
        label: 'Company overview',
      },
      TextFieldType.Textarea
    );

    this.certificationsField = new ListField({
      id: 'certifications',
      name: 'certifications',
      label: 'Licenses/Certifications/Insurance',
      value: this.userInfo?.certifications || [],
    });

    this.workingHoursField = new HoursField({
      id: 'workingHours',
      name: 'workingHours',
      value: this.userInfo?.workingHours || [],
      validators: [new DayScheduleValidator()],
    });

    this.featuredWorkField = new ImageField(
      {
        id: 'featuredWork',
        name: 'featuredWork',
        validators: [
          new MultipleImageFieldValidator(
            'images' /** Entity names */,
            20 /** Maximum 20 files */,
            5 /** Maximum 5 Mb for images */,
            50 /** Maximum 50 Mb for videos */
          ),
        ],
        value: this.userInfo
          ? [
              ...(this.userInfo.featuredWork?.map(
                (image) => new UploadedFile(image.objectKey, image.url, image.description)
              ) || []),
            ]
          : undefined,
      },
      true, // multiple
      true, // allowsVideo
      'Upload' // buttonText
    );

    this.editProfileFields = [
      userFields.firstNameField,
      userFields.lastNameField,
      this.companyNameField,
      ...userFields.addressFields,
      userFields.emailField,
      ...userFields.contactDetailsFields,
      this.serviceAreaField,
      userFields.profilePictureField,
    ];
  }
}

export class ClientManagerFields {
  public userInfo?: IUserInfo;

  public editProfileFields: Field[];

  constructor(userInfo?: IUserInfo) {
    this.userInfo = userInfo;

    const userFields = new UserFields(userInfo);

    this.editProfileFields = [
      userFields.firstNameField,
      userFields.lastNameField,
      ...userFields.addressFields,
      userFields.phoneField,
    ];
  }
}
