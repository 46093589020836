import { useState } from 'react';

const usePagination = (numberOfPages?: number, initialPage?: number) => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(initialPage ?? 0);

  const changePage = (newPageNumber: number) => {
    if (newPageNumber >= 0 && (!numberOfPages || newPageNumber < numberOfPages))
      setCurrentPageIndex(newPageNumber);
  };

  return {
    currentPage: currentPageIndex,
    changePage,
  };
};

export default usePagination;
