import React from 'react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from '../authStore';
import { useUserStore } from '../../../../../../store/user';
import { EmailActivationModal } from '../../emailActivation/EmailActivationModal';
import { IUserInfo } from '../../../../../../model/UserInfo';

interface Props {
  auth: AuthStore;
}

export const ProfileAuthStep: React.FC<React.PropsWithChildren<Props>> = observer(
  function ProfileAuthStep({ auth }) {
    const user = useUserStore();

    return (
      <EmailActivationModal
        onDismiss={auth.onDismiss}
        onAddProfileDetails={auth.formSubmit}
        userInfo={user.getUserInfo() as IUserInfo}
        onCompleteSignup={auth.completedStep}
      />
    );
  }
);
