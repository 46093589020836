import { useEffect } from 'react';

function useOutsideClick(onOutsideClick: () => void, isOutside: (target: HTMLElement) => boolean) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent | KeyboardEvent) {
      const target = event.target as HTMLElement;
      if (
        (event instanceof MouseEvent && isOutside(target)) ||
        (event instanceof KeyboardEvent && event.code === 'Escape')
      ) {
        onOutsideClick();
      }
    }
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keyup', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
    };
  }, [onOutsideClick, isOutside]);
}

export default useOutsideClick;
