import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import styles from './Step.module.scss';

type StepProps = {
  active: boolean;
  page: number;
};

export const Step = observer(({ page, active }: StepProps) => (
  <div aria-hidden="true" className={classNames(styles.step, active && styles.stepActive)}>
    {page}
  </div>
));
