import React, { ReactNode } from 'react';
import { Close, Title } from '@radix-ui/react-dialog';
import { observer } from 'mobx-react-lite';
import Image from "next/legacy/image";

import { CloseButton } from '../../../atoms/CloseButton';

import styles from './CommonModalHeader.module.scss';

type CommonModalHeaderProps = {
  iconPath?: string;
  showCloseButton?: boolean;
  title: ReactNode | ReactNode[];
};

export const CommonModalHeader = observer(
  ({ iconPath, title, showCloseButton = true }: CommonModalHeaderProps) => (
    <header className={styles.modalHeader}>
      <Title asChild>
        <div className={styles.modalTitleContainer}>
          {iconPath && (
            <Image className={styles.icon} src={iconPath} width="43" height="37" alt="" />
          )}
          <h2 className={styles.title}>{title}</h2>
        </div>
      </Title>
      {showCloseButton && (
        <Close asChild>
          <CloseButton className="close-dialog" label="close dialog" />
        </Close>
      )}
    </header>
  )
);

CommonModalHeader.displayName = 'CommonModalHeader';
