import React, { useRef, useState, ReactElement } from 'react';
import { Field, ImageField } from '../../types/Field';
import { UploadFilesImagePreview, UploadPreview } from './UploadFilesImagePreview';
import classNames from 'classnames';
import { UploadIcon } from '@radix-ui/react-icons';
import InputError from './InputError';
import { observer } from 'mobx-react-lite';

export interface ImageUploadFieldProps {
  buttonContainerAlignment?: string;
  /**
   * Field details
   */
  field: ImageField;
  /**
   * Optional handler for when the field value changes
   */
  onChange: (field: Field, newValue: any) => void;

  preview?: boolean;

  placeholder?: ReactElement;
  /**
   * Indicates whether to show the upload new button
   */
  showUploadButton?: boolean;

  className?: string;
  extendedLabel?: string | ReactElement;
}

export const ImageUploadField: React.FC<ImageUploadFieldProps> = observer(
  ({
    buttonContainerAlignment = 'text-center',
    className,
    field,
    onChange,
    preview,
    placeholder,
    showUploadButton = true,
    extendedLabel,
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

    const handleUpload = () => {
      inputRef.current?.click();
    };

    const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files) {
        let appendToPreviousFiles = true;
        if (files.length === 1 && !field.multiple) {
          setUploadedFileName(files[0].name);
          appendToPreviousFiles = false; // If it's a single field, keep the current upload only
        }
        const success = await field.onAddFiles(Array.from(files), appendToPreviousFiles);
        // check success so you don't overwrite field.error with validate
        if (success) {
          onChange(field, field.value);
        }
      }
    };

    const onFileRemove = (key: string) => {
      let files = field.value;
      if (!files) return;
      files = files.filter((file) => file.objectKey !== key);
      onChange(field, files);
    };

    const onFileDescriptionChange = (key: string, newDescription: string) => {
      let files = field.value;
      if (!files) return;
      files = files.map((file) => {
        if (file.objectKey === key) {
          file.description = newDescription;
        }
        return file;
      });
      onChange(field, files);
    };

    const buttonLabel = (
      <>
        <UploadIcon />
        &nbsp;&nbsp;{field.buttonText || 'Click to choose image file'}
      </>
    );
    const acceptedFormats = `${
      field.allowsVideo ? 'video/mp4,video/quicktime,' : ''
    }image/jpeg,image/png,application/pdf`;

    return (
      <>
        <div className={classNames(className)}>
          {field.multiple && field.value && field.value.length > 0 && preview && (
            <UploadFilesImagePreview
              files={field.value || []}
              onRemoveFile={onFileRemove}
              onFileDescriptionChange={onFileDescriptionChange}
            />
          )}
          {!field.multiple && preview && (
            <UploadPreview
              placeholder={placeholder}
              file={field.value && field.value[0]}
              altText={`User File ${field.name}`}
              // caller should add their own button for removing
              // onClick={() => {
              //   if (field.value) onFileRemove(field.value[0].key);
              // }}
            />
          )}

          <div className="input-field-container">
            <input
              ref={inputRef}
              type="file"
              name={field.name}
              accept={acceptedFormats}
              onChange={onFileUpload}
              multiple={field.multiple}
            />

            {showUploadButton && (
              <div className={classNames(buttonContainerAlignment, 'extended-label')}>
                {extendedLabel}
                <button type="button" onClick={handleUpload} className="btn primary upload">
                  {buttonLabel}
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

ImageUploadField.displayName = 'ImageUploadField';
