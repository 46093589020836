import React, { HTMLAttributes } from 'react';
import styles from './CloseButton.module.scss';
import classNames from 'classnames';
import { Cross2Icon } from '@radix-ui/react-icons';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  label: string;
}

export const CloseButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, label, children, ...rest }, forwardedRef) => (
    <button
      aria-label={label}
      type="button"
      className={classNames(styles.close, className)}
      ref={forwardedRef}
      {...rest}
    >
      {children || <Cross2Icon height="1.5em" width="1.5em" />}
    </button>
  )
);
