import Link from 'next/link';

import { IUserInfo } from '../../../model/UserInfo';

import { NavbarAction } from '../AppNavbar';

import styles from './AppMainNav.module.scss';

type ClientProviderNavProps = {
  onClickCallback: (showSidebar: boolean) => void;
  user: IUserInfo;
};

export const UserNavClient = ({ onClickCallback, user }: ClientProviderNavProps) => {
  const navLinks: NavbarAction[] = [
    {
      uri: '/proposals/create',
      label: 'Create a Job Request',
    },
    {
      uri: `/users/${user.id}/jobs`,
      label: 'Your Job Requests',
    },
    {
      uri: `/users/${user.id}/saved/providers`,
      label: 'Saved Providers',
      onClick: () => onClickCallback(false),
    },
  ];

  return (
    <>
      {navLinks.map((link) => (
        <li className={styles.links} key={link.label} role="none">
          <Link role="menuitem" href={link.uri} onClick={link.onClick}>
            {link.label}
          </Link>
        </li>
      ))}
    </>
  );
};

UserNavClient.displayName = 'UserNavClient';
