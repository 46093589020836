/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes, useState } from 'react';
import { TextLikeField } from '../../types/Field';
import classNames from 'classnames';
import InputError from './InputError';
import { EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons';
import styles from './PasswordField.module.scss';
import { Input } from './Input';
import { observer } from 'mobx-react-lite';

interface Props {
  /**
   * Field details
   */
  field: TextLikeField;
  /**
   * Optional handler for when the field value changes
   */
  onChange?: (field: TextLikeField, newValue: any) => void;

  disabled?: boolean;
  className?: string;

  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'className'>;
}

export const PasswordField: React.FC<Props> = observer(
  ({ field, onChange, disabled, className, inputProps }) => {
    const [show, setShow] = useState(false);
    const onChangeHandler = (event: any) => {
      if (onChange) onChange(field, event.target.value);
    };

    const isInvalid = field.isInvalid();
    const errorId = `${field.id}-error`;
    const inputField = (
      <Input
        type={show ? 'text' : 'password'}
        name={field.name}
        value={field.value}
        disabled={disabled}
        invalid={isInvalid}
        className={classNames(className, isInvalid && 'invalid__icon')}
        onChange={onChangeHandler}
        aria-describedby={errorId}
        placeholder={field.placeholder || ''}
        spellCheck={false}
        id={field.id}
        {...inputProps}
      />
    );

    if (field.label) {
      return (
        <div className="field">
          <label>
            <span className="input-label-text">{field.label}</span>
            <div className={styles.wrap}>
              {inputField}
              <button
                type="button"
                aria-label={show ? 'hide password' : 'show password'}
                className={styles.toggleButton}
                onClick={() => setShow(!show)}
              >
                {!show ? <EyeNoneIcon /> : <EyeOpenIcon />}
              </button>
            </div>
            <InputError id={errorId} error={field.error} />
          </label>
        </div>
      );
    }

    return inputField;
  }
);

PasswordField.displayName = 'PasswordField';
