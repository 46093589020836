import React, { MouseEventHandler } from 'react';

import { observer } from 'mobx-react-lite';

import { useUserStore } from '../../store/user';

import { Logo } from '../atoms/Logo';
import { AppMainNav } from './AppMainNav/AppMainNav';
import { AnnounceBox } from '../AnnounceBox/AnnounceBox';
import { AnnounceManager } from '../AnnounceBox/AnnounceManager';

import styles from './AppNavbar.module.scss';

export interface NavbarLink {
  uri: string;
  label: string;
  onClick?: undefined;
}

export interface NavbarButton {
  uri: string;
  onClick: MouseEventHandler<HTMLElement>;
  label: string;
}

export type NavbarAction = NavbarLink | NavbarButton;

interface AppNavbarProps {
  hasNavigation?: boolean;
}

export const AppNavbar: React.FC<AppNavbarProps> = observer(({ hasNavigation = true }) => {
  const user = useUserStore();
  const isManager = Boolean(user.getManagerId());

  return (
    <>
      <header className={styles.pageHeader}>
        <div className={styles.appNavBarContainer}>
          <Logo />
          {hasNavigation && (
            <div className={styles.navigationWrapper}>
              <AppMainNav />
            </div>
          )}
        </div>
      </header>
    </>
  );
});

AppNavbar.displayName = 'AppNavbar';
