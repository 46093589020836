import React, { FormEventHandler, HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './SignInWith.module.scss';
import { SSOProviders } from '../templates/modals/users/login/authStore';

interface Props extends Omit<HTMLAttributes<HTMLButtonElement>, 'children' | 'type'> {
  provider: SSOProviders;
  displayName: string;
  action: string;
  csrfToken: string;
}

export const SignInWith: React.FC<Props> = ({
  provider,
  action,
  displayName,
  csrfToken,
  ...props
}) => {
  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    window.open('', 'formpopup');
    (event.nativeEvent.target as HTMLFormElement).target = 'formpopup';
    return true;
  };

  return (
    <form className={styles.form} action={action} onSubmit={onSubmit} method="POST">
      <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
      <button
        type="submit"
        className={classNames('btn', styles.button, props.className)}
        {...props}
      >
        <span className={classNames('icon', provider)} /> Sign in with {displayName}
      </button>
    </form>
  );
};

SignInWith.displayName = 'SignInWith';
