import { ReactNode } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Step } from './Step';

import styles from './StepNavigation.module.scss';

type StepNavigationProps = {
  count: number; // starts at 0
  current: number;
};

export const StepNavigation = observer(({ count, current }: StepNavigationProps) => {
  const steps: ReactNode[] = [];
  for (let i = 0; i < count; i++) {
    steps.push(
      <li key={`step-${i}`}>
        <Step page={i + 1} active={current === i} />
      </li>
    );
  }

  return (
    <nav aria-label="Job request navigation">
      <ul className={classNames('reset-list', styles.stepsNavigation)}>{steps}</ul>
    </nav>
  );
});
