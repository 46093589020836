import type { ActionAlert } from '../../types/ActionAlert';
import { ActionAlertType } from '../../types/ActionAlert';
import { Announce } from '@radix-ui/react-announce';
import React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
  alert?: ActionAlert | string | null;
};

export const FormAlert: React.FC<Props> = ({ className, alert }) => {
  if (!alert) return null;

  if (typeof alert === 'string') {
    alert = { type: ActionAlertType.Danger, text: alert };
  }
  if (alert.type === ActionAlertType.Danger) {
    console.error('FormError: %O', alert);
  }

  return (
    <div className={classNames('text-center', className)}>
      <div className={alert.type === ActionAlertType.Success ? 'text-success' : 'text-danger'}>
        <Announce type="assertive">{alert.text}</Announce>
      </div>
    </div>
  );
};
