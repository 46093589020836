import { observer } from 'mobx-react-lite';

import { IUserInfo } from '../../model/UserInfo';

type Props = {
  height?: number;
  profile?: IUserInfo;
  width?: number;
};

export const Avatar = observer(({ height, profile, width }: Props) => (
  <img
    className="avatar"
    height={height}
    src={profile?.profilePicture || '/static/unknown-client-gray.svg'}
    alt=""
    width={width}
  />
));
