import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useUserStore } from '../../../store/user';
import { useSiteStore } from '../../../store/site';

import { Spinner } from '../../atoms/Spinner';
import { AlertIcon } from '../../../images/AlertIcon';
import { NavAlert } from './NavAlert';

import styles from './NavAlerts.module.scss';

export const NavAlerts = observer(function NavAlerts() {
  const authContext = useUserStore();
  const user = authContext.getUserInfo();
  const alertsRef = useRef<HTMLLIElement>(null);
  const site = useSiteStore();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;

      if (alertsRef.current && !alertsRef.current.contains(target)) {
        site.showAlertsDropdown = false;
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [site]);

  if (!authContext.isAuthenticated() || !user) {
    return null;
  }

  return (
    <li id="alerts-container" ref={alertsRef}>
      <button
        aria-expanded={site.showAlertsDropdown}
        aria-controls="alerts-menu"
        aria-haspopup="true"
        className={classNames(
          'btn plain',
          styles.toggle,
          site.showAlertsDropdown && styles.toggleIsOpen
        )}
        onClick={() => {
          site.showAlertsDropdown = !site.showAlertsDropdown;
        }}
        type="button"
        data-testid="alerts-toggle"
      >
        <AlertIcon />
        {site.unreadCount > 0 && (
          <span
            data-testid="alerts-unread"
            data-count={site.unreadCount}
            className={styles.unreadCount}
          />
        )}
      </button>
      {site.showAlertsDropdown && (
        <div
          aria-labelledby="alerts-container"
          role="menu"
          id="alerts-menu"
          className={classNames(styles.dropdown)}
        >
          <div className={styles.header}>
            <span className="caption">NOTIFICATIONS</span>
            <button type="button" className="btn link" onClick={() => site.markAllAsRead()}>
              Mark all as read
            </button>
          </div>
          {site.isLoadingAlerts && <Spinner />}
          {!site.isLoadingAlerts && (
            <ul className="reset-list">
              {!site.alerts.length && <li>No alerts yet!</li>}
              {site.alerts.map((alert) => (
                <NavAlert key={alert.id} alert={alert} />
              ))}
            </ul>
          )}
        </div>
      )}
    </li>
  );
});
