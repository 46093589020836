import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { IFile, PendingUploadFile } from '../../types/Field';

import { FilePreview } from './FilePreview';
import InputError from './InputError';

import { TrashIcon } from '../../images/TrashIcon';

import styles from './UploadFilesImagePreview.module.scss';

type UploadPreviewProps = {
  className?: string;
  file?: IFile;
  altText: string;
  placeholder?: ReactElement;
};

export const UploadPreview: React.FC<UploadPreviewProps> = ({
  className,
  file,
  altText,
  placeholder,
}) => (
  <figure className={className}>
    {file ? (
      <FilePreview aria-describedby={file.objectKey} file={file} altText={altText} />
    ) : (
      placeholder
    )}
    {file instanceof PendingUploadFile && file.error && (
      <InputError error={file.error} id={file.objectKey} />
    )}
  </figure>
);

interface FileUploadImagePreviewProps {
  /**
   * List of image files
   */
  files: IFile[];
  /**
   * Handler that's called when the user wants to remove one of the file uploads
   */
  onRemoveFile: (key: string) => void;
  /**
   * Handler that's called when the user wants to edit one of the files' descriptions
   */
  onFileDescriptionChange: (key: string, newDescription: string) => void;
}

export const UploadFilesImagePreview: React.FC<FileUploadImagePreviewProps> = ({
  files,
  onRemoveFile,
  onFileDescriptionChange,
}) => (
  <ul className={classNames('reset-list', styles.uploadPreviewContainer)}>
    {files.map((file, index) => (
      <li key={file.objectKey} className={styles.uploadPreview}>
        <UploadPreview file={file} altText={`User File ${index + 1}`} />

        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type="button" className="btn icon" onClick={() => onRemoveFile(file.objectKey)}>
          <TrashIcon />
        </button>

        <textarea
          id={`${file.objectKey}_description`}
          name={`${file.objectKey}_description`}
          value={file.description}
          placeholder="Add more info about this photo"
          onChange={(e) => onFileDescriptionChange(file.objectKey, e.target.value)}
        />
      </li>
    ))}
  </ul>
);
