import { ISelectOptionWithContent } from '../model/helper';

const generateTextWithSupport = (text: string, supportText: string) => (
  <>
    {text} <span className="fw-light">({supportText})</span>
  </>
);

export type ModificationServiceName =
  | 'accessibility_safety_assessment'
  | 'kitchen_modification'
  | 'home_entry_exit_modification'
  | 'bathroom_modification'
  | 'bedroom_modification'
  | 'patio_terrace_modification'
  | 'garage_modification'
  | 'stairway_modification'
  | 'storage_closet_modification'
  | 'window_modification'
  | 'hallway_path_modification'
  | 'lighting_visibility_modification';

type ModificationServiceFilteredProps = {
  value: ModificationServiceName;
  subServices: string[];
  label: string;
};

export interface ModificationService extends ISelectOptionWithContent {
  value: ModificationServiceName;
  providerServiceDescriptionLabel: string;
  subServices: string[];
  clientLabel?: string;
  iconClass: string;
}

export enum ModificationServicesValues {
  AccessibilitySafetyAssessment = 'accessibility_safety_assessment',
  BathroomModification = 'bathroom_modification',
  BedroomModification = 'bedroom_modification',
  HomeEntryExitModification = 'home_entry_exit_modification',
  LightingVisibilityModification = 'lighting_visibility_modification',
  KitchenModification = 'kitchen_modification',
  StairwayModification = 'stairway_modification',
  PatioTerraceModification = 'patio_terrace_modification',
  StorageClosetModification = 'storage_closet_modification',
}

export const ModificationServices: ModificationService[] = [
  {
    label: 'Home accessibility safety assessment',
    clientLabel: "I'm unsure exactly what accessibility modifications I need",
    value: ModificationServicesValues.AccessibilitySafetyAssessment,
    content: generateTextWithSupport(
      'Home accessibility safety assessment',
      "I'm unsure exactly what accessibility modifications I need"
    ),
    providerServiceDescriptionLabel:
      'I can conduct an analysis on accessible home modification services needed.',
    subServices: ['Home Accessibility Safety Assessment'],
    iconClass: 'general',
  },
  {
    label: 'Navigating a bedroom',
    clientLabel: 'Navigating my bedroom',
    value: ModificationServicesValues.BedroomModification,
    content: generateTextWithSupport('Bedroom modification', 'closet, bed adjustments, etc.'),
    providerServiceDescriptionLabel: 'I can help with navigating around a bedroom.',
    subServices: ['Ceiling lift', 'Closet adjustments', 'Bed adjustments'],
    iconClass: 'bedroom',
  },
  {
    label: 'Navigating around a kitchen',
    clientLabel: 'Navigating my kitchen',
    value: ModificationServicesValues.KitchenModification,
    content: generateTextWithSupport(
      'Kitchen modification',
      'sink, counter top, stove/dishwasher adjustments, etc.'
    ),
    providerServiceDescriptionLabel: 'I can help with navigating around a kitchen.',
    subServices: ['Sink adjustments', 'Countertop adjustments', 'Stove/dishwasher adjustments'],
    iconClass: 'kitchen',
  },
  {
    label: 'Navigating between areas of a home',
    clientLabel: 'Navigating within areas/rooms of my home',
    value: ModificationServicesValues.StairwayModification,
    providerServiceDescriptionLabel: 'I can help with navigating within areas/rooms of a home.',
    subServices: ['Stairway modification', 'Hallway path modification'],
    iconClass: 'stairs',
  },
  {
    label: 'Navigating external facing areas of a home',
    clientLabel: 'Navigating externally facing areas of my home (patio, garage, etc.)',
    value: ModificationServicesValues.PatioTerraceModification,
    content: generateTextWithSupport(
      'Patio / terrace modification',
      'floor, pathway, furniture adjustments, etc.'
    ),
    providerServiceDescriptionLabel:
      'I can help with navigating externally facing areas of a home.',
    subServices: ['Floor', 'Pathway', 'Furniture Adjustments', 'Storage Adjustments'],
    iconClass: 'exterior',
  },
  {
    label: 'Storage',
    clientLabel: 'Storage in my home',
    value: ModificationServicesValues.StorageClosetModification,
    providerServiceDescriptionLabel: 'I can help with storage within a home.',
    subServices: ['Storage / closet modification'],
    iconClass: 'storage',
  },
  {
    label: 'Navigating a bathroom',
    clientLabel: 'Navigating my bathroom',
    value: ModificationServicesValues.BathroomModification,
    content: generateTextWithSupport(
      'Bathroom modification',
      'bathtub grab bar, toilet adjustments, flooring, etc.'
    ),
    providerServiceDescriptionLabel: 'I can help with navigating a bathroom.',
    subServices: [
      'Bathtub grab bar',
      'Toilet adjustments',
      'Flooring adjustments',
      'Roll-in Shower',
    ],
    iconClass: 'bathroom',
  },
  {
    label: 'Getting into or out of a home',
    clientLabel: 'Getting into or out of my home',
    value: ModificationServicesValues.HomeEntryExitModification,
    content: generateTextWithSupport(
      'Home entryway / exit modification',
      'lifts, ramps, door adjustments, etc.'
    ),
    providerServiceDescriptionLabel: 'I can help with getting into or out of a home.',
    subServices: ['Elevator lift', 'Ramps', 'Door adjustments'],
    iconClass: 'entrance',
  },
  {
    label: 'Lighting and visibility within a home',
    clientLabel: 'Lighting and visibility within my home',
    value: ModificationServicesValues.LightingVisibilityModification,
    providerServiceDescriptionLabel: 'I can help with lighting and visibility within a home.',
    subServices: ['Window modification', 'Lighting modification'],
    iconClass: 'lighting',
  },
];

export const ModificationServiceMap: { [key in ModificationServiceName]: ModificationService } =
  Object.assign({}, ...ModificationServices.map((s) => ({ [s.value]: s })));

/**
 * A reduced representation of `ModificationServices` suitable to store in the database.
 * Entries in this object should be used with the `ProviderServicesForm` component.
 * @example {
 *  accessibility_safety_assessment: {
 *    label: 'Home accessibility safety assessment',
 *    subServices: ['Home Accessibility Safety Assessment'],
 *    value: 'accessibility_safety_assessment',
 * }
 * @returns {Record<ModificationServiceName, ModificationServiceFilteredProps>}
 */
export const ModificationServicesFilteredProps = Object.fromEntries(
  ModificationServices.map((service) => {
    const { label, subServices, value } = service;
    return [service.value, { label, subServices, value }];
  })
);
