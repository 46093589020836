import { useSiteStore } from '../../store/site';
import { CommonModal } from '../templates/modals/common/CommonModal';
import { CommonModalContent } from '../templates/modals/common/CommonModalContent';
import { CommonModalFooter } from '../templates/modals/common/CommonModalFooter';
import { CommonModalHeader } from '../templates/modals/common/CommonModalHeader';

type Props = {
  title: string;
};

export const MessageModal: React.FC<React.PropsWithChildren<Props>> = ({ title, children }) => {
  const siteStore = useSiteStore();
  const onDismiss = () => {
    siteStore.showModal(false);
  };
  return (
    <CommonModal title={title} onDismiss={onDismiss}>
      <CommonModalHeader title={title} />
      <CommonModalContent>{children}</CommonModalContent>
      <CommonModalFooter>
        <button type="button" className="btn" onClick={onDismiss}>
          Close
        </button>
      </CommonModalFooter>
    </CommonModal>
  );
};

MessageModal.displayName = 'MessageModal';
