/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from 'react';
import useApiRequest from '../../hooks/useApiRequest';
import { ActionAlertType } from '../../types/ActionAlert';
import { ApiResponse } from '../../util/ApiRequest';
import classNames from 'classnames';
import { VisuallyHidden } from '../atoms/VisuallyHidden';
import { Alert } from '../organisms/Alert';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Handler for when the button is clicked.
   */
  onClick: () => Promise<ApiResponse>;
  /**
   * Optional callback for when the request resolves successfully
   */
  successCallback?: (response: ApiResponse) => void;
  /**
   * If defined, will set the alert to this message
   */
  successMessage?: string;
}

export const ButtonWithStatus: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  successCallback,
  children,
  className,
  type,
  ...props
}) => {
  const { isLoading, actionAlert, setActionAlert, onRequest } = useApiRequest(
    onClick,
    successCallback
  );

  return (
    <>
      <button
        type={type || 'button'}
        {...props}
        disabled={isLoading}
        className={classNames('btn', className, isLoading && 'loading')}
        onClick={onRequest}
      >
        {children}
      </button>
      {isLoading && <VisuallyHidden>Loading...</VisuallyHidden>}
      {actionAlert && (
        <Alert title="There was an error" onDismiss={() => setActionAlert(undefined)}>
          {actionAlert.text}
        </Alert>
      )}
    </>
  );
};
