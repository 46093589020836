import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';

import { observer } from 'mobx-react-lite';

import styles from './CommonModal.module.scss';

type CommonModalProps = {
  children: React.ReactNode;
  className?: string;
  onDismiss?: () => void;
  title?: string;
};

export const CommonModal = observer(
  ({ title, children, className, onDismiss }: CommonModalProps) => (
    <Dialog.Root open onOpenChange={onDismiss}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay}>
          <Dialog.Content title={title} className={classNames(styles.modal, className)}>
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
);

CommonModal.displayName = 'CommonModal';
