import type { ActionAlert } from '../../../../../types/ActionAlert';
import classNames from 'classnames';
import { LeftArrowIcon } from '../../../../../images/LeftArrowIcon';
import { RightArrowIcon } from '../../../../../images/RightArrowIcon';
import { FormAlert } from '../../../../molecules/FormAlert';
import React, { ReactNode } from 'react';

import styles from './ProposalModalFooter.module.scss';

type Props = {
  isLoading: boolean;
  onBack?: () => void;
  alert?: ActionAlert;
  hasBack?: boolean;
  editing: boolean;
  next?: ReactNode;
};

export const ProposalModalFooter: React.FC<React.PropsWithChildren<Props>> = ({
  alert,
  isLoading,
  onBack,
  hasBack = true,
  editing,
  next,
}) => {
  if (hasBack && !onBack) {
    throw new Error('Expected an onBack function');
  }

  if (!next) {
    if (editing) {
      next = 'Save';
    } else {
      next = (
        <>
          Next
          <RightArrowIcon />
        </>
      );
    }
  }

  return (
    <div className={styles.buttonWrapper}>
      {alert && <FormAlert className="text-right" alert={alert} />}
      {hasBack && !editing && (
        <button type="button" className="btn secondary" disabled={isLoading} onClick={onBack}>
          <LeftArrowIcon />
          &nbsp;&nbsp; Back
        </button>
      )}

      {editing && (
        <button type="button" className="btn secondary" disabled={isLoading} onClick={onBack}>
          Cancel
        </button>
      )}
      <button
        type="submit"
        data-testid="proposal-next-button"
        className={classNames('btn red', isLoading && 'loading')}
        disabled={isLoading}
      >
        {next}
      </button>
    </div>
  );
};
