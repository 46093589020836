import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useUserStore } from '../../../store/user';
import { NavbarAction } from '../AppNavbar';
import { UserRole } from '../../../model/UserInfo';

import styles from './AccountNav.module.scss';

export const AccountNav = observer(function AccountNav() {
  const authContext = useUserStore();
  const router = useRouter();

  const navLinks: NavbarAction[] = [
    { uri: '/profile-settings/change-password', label: 'Profile Settings' },
    {
      onClick: () => {
        authContext.logout();
        router.push('/');
      },
      label: 'Log out',
      uri: '/logout',
    },
  ];

  if (authContext.getManagerId() || authContext.getUserRole() === UserRole.Manager) {
    navLinks.unshift(
      { uri: '/profile-settings/manage-clients', label: 'Patient Management' },
      { uri: '/profile-settings/edit-profile', label: 'Edit Profile' }
    );
  }

  return (
    <>
      <li className={styles.sectionTitle} role="none">
        <span>Account</span>
      </li>
      {authContext.getUserRole() === UserRole.Provider && (
        <li role="none">
          <Link href={`/users/${authContext.getUserId()}`}>My Profile</Link>
        </li>
      )}
      {navLinks.map((link) => (
        <li key={link.uri} role="none">
          <Link href={link.uri} onClick={link.onClick}>
            {link.label}
          </Link>
        </li>
      ))}
    </>
  );
});
