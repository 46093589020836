export function getAutocompleteAddressComponent(addressComponents: any, addressType: string) {
  if (addressType === 'streetAddress') {
    const addressTypes = ['street_number', 'route'];
    const address = addressComponents
      .filter((currentValue: { long_name: string; short_name: string; types: string[] }) =>
        addressTypes.includes(currentValue.types[0])
      )
      .map(
        (currentValue: { long_name: string; short_name: string; types: string[] }) =>
          currentValue.long_name
      );
    return address.join(' ');
  }

  if (addressType === 'city' || addressType === 'state' || addressType === 'zipCode') {
    const addressTypeMap = {
      city: 'locality',
      state: 'administrative_area_level_1',
      zipCode: 'postal_code',
    };
    const addressComponent = addressComponents
      .filter(
        (currentValue: { long_name: string; short_name: string; types: string[] }) =>
          currentValue.types[0] === addressTypeMap[addressType]
      )
      .map((currentValue: { long_name: string; short_name: string; types: string[] }) =>
        addressType === 'state' ? currentValue.short_name : currentValue.long_name
      );
    return addressComponent[0];
  }
}
