import { useState } from 'react';
import type { ActionAlert } from '../types/ActionAlert';
import { ActionAlertType } from '../types/ActionAlert';
import { ApiResponse, ApiResponseStatus } from '../util/ApiRequest';

const useApiRequest = (
  makeRequest: (values: any) => Promise<ApiResponse>,
  successCallback?: (response: ApiResponse) => void,
  successMessage?: string
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionAlert, setActionAlert] = useState<ActionAlert>();

  const onRequest = async (values: any) => {
    setIsLoading(true);
    setActionAlert(undefined);
    const response = await makeRequest(values);
    setIsLoading(false);
    if (response.status === ApiResponseStatus.Error) {
      let alertText = '';
      const error: any = response.data;
      if (error instanceof Array)
        error.forEach((er: any) => {
          if (!er.param) alertText += JSON.stringify(er);
        });
      else alertText = error;
      setActionAlert({ text: alertText, type: ActionAlertType.Danger });
    } else {
      if (successMessage) setActionAlert({ text: successMessage, type: ActionAlertType.Success });
      if (successCallback) successCallback(response);
    }
    return response;
  };

  return {
    isLoading,
    actionAlert,
    setActionAlert,
    onRequest,
  };
};

export default useApiRequest;
