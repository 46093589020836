class LocalStorage implements Storage {
  // eslint-disable-next-line no-restricted-globals
  [name: string]: any;

  get length(): number {
    try {
      return window.localStorage.length;
    } catch (e) {
      console.error(e);
      return Number.NaN;
    }
  }

  clear(): void {
    try {
      window.localStorage.clear();
    } catch (e) {
      console.error(e);
    }
  }

  getItem(key: string): string | null {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  key(index: number): string | null {
    try {
      return window.localStorage.key(index);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  removeItem(key: string): void {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }

  setItem(key: string, value: string): void {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
    }
  }
}

export const localStorage = new LocalStorage();
