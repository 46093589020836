import React, { ReactElement, ReactNode } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import styles from './BaseModal.module.scss';
import { Cross2Icon } from '@radix-ui/react-icons';

export interface BaseModalProps {
  className?: string;
  contentClassName?: string;

  /**
   * Modal title
   */
  title?: string | ReactElement | ReactElement[];
  /**
   * aria label
   * or true to reuse title
   */
  label: string | true;
  /**
   * Callback for when the operation is cancelled or the modal is closed
   */
  onDismiss?: () => void;
  /**
   * Optional content to show at the footer of the modal - can be list of buttons, notices etc
   */
  footerContent?: ReactNode;
  /**
   * Defines what width the modal will have
   */
  size?: 'sm' | 'lg' | 'xl';

  theme?: 'confirm' | 'activation';

  /**
   * every once in a while you need a modal that doesn't have the padding and decorations.
   */
  bare?: boolean;

  /**
   * disable the close X, does not affect other ways of closing
   * this is just to provide your own close
   */
  close?: boolean;
}

export const BaseModal: React.FC<React.PropsWithChildren<BaseModalProps>> = ({
  className,
  title,
  label,
  onDismiss,
  footerContent,
  size,
  close = true,
  bare = false,
  children,
  theme = 'base',
  contentClassName,
}) => {
  let titleContent: null | ReactElement | ReactElement[] = null;
  if (title && typeof title !== 'string') {
    // it's a component
    titleContent = title;
    title = undefined;
  }
  if (label === true) {
    if (!title) throw new Error('Title is required if label is true');
    label = title;
  }

  return (
    <Dialog.Root open onOpenChange={onDismiss} modal={false}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={classNames(className, size, styles.modal, styles[theme])}
          aria-label={label}
        >
          <div className={styles.inner}>
            <header className={styles.modalHeader}>
              {title && (
                <Dialog.Title className={classNames(styles.title, bare && 'bare')}>
                  {title}
                </Dialog.Title>
              )}
              {close && (
                <Dialog.Close className="black btn icon" aria-label="close dialog">
                  <Cross2Icon height="1em" width="1em" />
                </Dialog.Close>
              )}
            </header>
            {titleContent && <Dialog.Title asChild>{titleContent}</Dialog.Title>}

            <div
              className={classNames(
                styles.content,
                !footerContent && styles.contentNoFooter,
                !title && styles.contentNoTitle,
                bare && styles.contentBare,
                contentClassName
              )}
            >
              {children}
            </div>

            {footerContent && <div className={styles.footer}>{footerContent}</div>}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
