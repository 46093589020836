import { PartnerLogo } from '../types/MarketingPages';

export const EDIT_PROFILE_SUCCESS = 'Your profile has been updated successfully';
export const EDIT_PROFILE_ERROR = 'There was an error updating your profile';
export const NO_SERVICES_SELECTED = 'Please select at least one service or add a custom service.';

export const SEARCH_PROVIDER_PAGE_LABEL = 'Find A Provider';
export const SEARCH_PROVIDER_PAGINATION_LABEL = 'service providers';

export const BID_SERVER_CALCULATE_DELAY = 1500;
export const AUTOSAVE_FORM_DELAY = 3000;

export const AVATAR_FALLBACK_USER_IMG_URL = '/static/unknown-client-gray.svg';
export const AVATAR_FALLBACK_PROVIDER_IMG_URL = '/static/unknown-builder-gray.svg';

export const SUPPORT_EMAIL = 'support@rosariumhealth.com';
export const SUPPORT_PHONE = '(855) 650-0632';

export const PROVIDER_CONNECTED = 'You have connected with this client';

export const JOB_REQUEST_ACCEPT = 'Connect with Client';
export const JOB_REQUEST_CONTACT_INFO = 'View Contact Info';
export const JOB_REQUEST_DECLINE = 'Decline';
export const JOB_REQUEST_INVITATION_SENT = 'Invitation Sent';
export const JOB_REQUEST_NOT_INTERESTED = 'Not Interested';
export const JOB_REQUEST_RECONNECT = 'Reconnect with Client';
export const JOB_REQUEST_VIEW_JOB = 'View Job Request';

export const OT_ASSESSMENT_FORM_VERSION = 1;

export const CREATE_JOB_REQUEST_BUTTON_LABELS = {
  SELECT_STAGE: 'Next: Select Stage',
  UPLOAD_MEDIA: 'Next: Upload Media',
  ADDRESS_INFO: 'Next: Address Info',
  SUBMIT_REQUEST: 'Submit Request',
};

export enum NavigationKeys {
  JOBS = 'JOBS',
  LEADS = 'LEADS',
  SAVED_PROVIDERS = 'SAVED_PROVIDERS',
  USERS_HOME = 'USERS_HOME',
}

export const NavigationRegex: Record<NavigationKeys, RegExp> = {
  JOBS: /\/users\/[^/]+\/jobs/,
  LEADS: /\/users\/[^/]+\/leads/,
  SAVED_PROVIDERS: /^\/users\/[^/]+\/saved\/providers\/?$/,
  USERS_HOME: /^\/users\/[^/]+$/,
};

export const AnalyticsEventLabels = {
  JOB_REQUEST_DECLINED: 'proposal_declined',
  JOB_REQUEST_DECLINED_NO_INVITE: 'proposal_declined_no_invite',
};

export const PARTNER_LOGOS: PartnerLogo[] = [
  { name: 'Praia Health', source: '/static/partners/praia-health.svg', width: 100, height: 100 },
  { name: 'Ianacare', source: '/static/partners/ianacare.png', width: 123, height: 17 },
  { name: 'HMOTA', source: '/static/partners/hmota.png', width: 500, height: 100 },
  { name: 'Dementia Hub', source: '/static/partners/dementia-hub.jpeg', width: 434, height: 148 },
  {
    name: 'Contratista Direct',
    source: '/static/partners/contratista-direct.png',
    width: 707,
    height: 353,
  },
  {
    name: 'Therapy 2000 and Green Apple',
    source: '/static/partners/therapy-2000-green-apple.png',
    width: 404,
    height: 98,
  },
  {
    name: 'Columbia University',
    source: '/static/partners/columbia-university.png',
    width: 280,
    height: 107,
  },
  { name: 'A.T. Still University', source: '/static/partners/atsu.png', width: 272, height: 86 },
  {
    name: 'School of Occupational Therapy',
    source: '/static/partners/school-of-ot.png',
    width: 344,
    height: 91,
  },
  {
    name: 'VGM Live At Home',
    source: '/static/partners/vgm.png',
    width: 318,
    height: 159,
  },
];

export const ONSITE_LINKS = [
  'www.rosariumhealth.com',
  'www.accessrose.com',
  'staging.rosarium.dev',
  'localhost',
];

// Permissions
export const CAN_EDIT_PERMISSION = 'can_edit';
export const CAN_ADD_ASSESSMENT_FORM = 'add_rosarium_assessment';
