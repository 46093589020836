import Link from 'next/link';
import { ModalType, useSiteStore } from '../../../store/site';

type Props = {
  className?: string;
};

export const AuthSignupNav: React.FC<Props> = ({ className }) => {
  const site = useSiteStore();
  const navLinks = [
    {
      onClick: (event: { preventDefault: () => void }) => {
        event.preventDefault();
        site.showModal(ModalType.Login);
      },
      label: 'Log in',
      uri: '/login',
    },
    {
      onClick: (event: { preventDefault: () => void }) => {
        event.preventDefault();
        site.showModal(ModalType.Signup);
      },
      label: 'Sign up',
      uri: '/register',
    },
  ];

  return (
    <>
      {navLinks.map((link) => (
        <li className={className} key={link.uri} role="none">
          <Link href={link.uri} onClick={link.onClick}>
            {link.label}
          </Link>
        </li>
      ))}
    </>
  );
};
