import React from 'react';
import { TextLikeField } from '../../types/Field';
import InputError from './InputError';
import { Input, InputProps } from './Input';
import { observer } from 'mobx-react-lite';

interface Props {
  /**
   * Field details
   */
  field: TextLikeField;
  /**
   * Optional handler for when the field value changes
   */
  onChange?: (field: TextLikeField, newValue: any) => void;

  disabled?: boolean;
  required?: boolean;
  className?: string;
  selectOnFocus?: boolean;

  inputProps?: Omit<InputProps, 'onChange' | 'className'>;
}

export const InputField: React.FC<Props> = observer(
  ({ className, field, onChange, disabled, selectOnFocus = false, inputProps, required }) => {
    const onChangeHandler = (event: any) => {
      if (onChange) onChange(field, event.target.value);
    };
    const onFocus = selectOnFocus ? (event: any) => event.target.select() : undefined;

    const isInvalid = field.isInvalid();
    const errorId = `${field.id}-error`;
    const inputErrorProps = isInvalid ? { 'aria-describedby': errorId } : {};
    const inputField = (
      <>
        <Input
          className={className || undefined}
          type={field.type || 'text'}
          name={field.name}
          value={field.value}
          disabled={disabled}
          required={required}
          inputMode={field.inputMode || undefined}
          invalid={isInvalid}
          onChange={onChangeHandler}
          pattern={field.pattern || undefined}
          placeholder={field.placeholder || ''}
          id={field.id}
          onFocus={onFocus}
          {...inputErrorProps}
          {...inputProps}
        />
        <InputError id={errorId} error={field.error} />
      </>
    );

    if (field.label) {
      return (
        <div className="field">
          <label>
            <span className="input-label-text">{field.label}</span>
            {inputField}
          </label>
        </div>
      );
    }

    return inputField;
  }
);

InputField.displayName = 'InputField';
