import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { getAutocompleteAddressComponent } from '../util/geolocation';

export type AutocompletePrediction = {
  description: any;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
};

export const useAutoCompleteAddress = () => {
  const [error, setError] = useState<string>('');

  const {
    init,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us'] },
      types: ['address'],
    },
    initOnMount: false,
    debounce: 300,
  });

  useEffect(() => {
    const loader = new Loader({
      apiKey: globalThis.Rose.config.GOOGLE_API_FRONTEND_KEY,
      libraries: ['places'],
    });

    loader
      .importLibrary('places')
      .then(() => {
        init();
      })
      .catch((e) => {
        console.error('Error importing Google maps places API', e);
        setError(e);
      });
  }, [init]);

  async function getAddressComponents({ description }: AutocompletePrediction) {
    const results = await getGeocode({ address: description });
    const addressComponents = results[0].address_components;

    const selectedStreetAddress =
      getAutocompleteAddressComponent(addressComponents, 'streetAddress') || '';
    const selectedCity = getAutocompleteAddressComponent(addressComponents, 'city') || '';
    const selectedState = getAutocompleteAddressComponent(addressComponents, 'state') || '';
    const selectedZipCode = getAutocompleteAddressComponent(addressComponents, 'zipCode') || '';

    clearSuggestions();

    return { selectedStreetAddress, selectedCity, selectedState, selectedZipCode };
  }

  return {
    error,
    clearSuggestions,
    data,
    getAddressComponents,
    setValue,
    status,
    value,
  };
};
