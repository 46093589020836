import React from 'react';
import { IUserInfo, UserActivationStatus } from '../../../../../model/UserInfo';
import { FieldValueType, TextLikeField } from '../../../../../types/Field';
import { NameValueDict } from '../../../../../types/FormTypes';
import { ApiResponse, ApiResponseStatus } from '../../../../../util/ApiRequest';
import { useRouter } from 'next/router';
import { UserFields } from '../../../../../util/fields/UserFields';
import useCustomForm from '../../../../../hooks/useCustomForm';
import { InputField } from '../../../../atoms/InputField';
import { ModalHeader } from '../../../../molecules/ModalHeader';
import { AutoCompleteAddress } from '../../../../organisms/AutoCompleteAddress';
import { BaseForm } from '../../../../organisms/BaseForm';
import { CommonModal } from '../../common/CommonModal';
import { CommonModalFooter } from '../../common/CommonModalFooter';
import { CommonModalHeader } from '../../common/CommonModalHeader';

import styles from './EmailActivationModal.module.scss';

export interface EmailActivationModalProps {
  /**
   * Handler for when the operation is cancelled or the modal is closed
   */
  onDismiss: () => void;
  /**
   * Handler for when the client adds more information to their profile
   */
  onAddProfileDetails: (values: NameValueDict<FieldValueType>) => Promise<ApiResponse>;
  /**
   * Details of the logged in user
   */
  userInfo: IUserInfo;
  /*
   * Handler for when the user submits information to complete their signup
   */
  onCompleteSignup?: () => void;
}

export const EmailActivationModal: React.FC<React.PropsWithChildren<EmailActivationModalProps>> = ({
  onDismiss,
  onAddProfileDetails,
  userInfo,
  onCompleteSignup,
}) => {
  const router = useRouter();
  const redirectUrl = router.query.returnTo || '/';
  const completeSignup = () => {
    if (onCompleteSignup) {
      return onCompleteSignup();
    }
    onDismiss();
    router.push(String(redirectUrl));
  };

  const initialFields = new UserFields(userInfo).contactDetailsFields;

  const onSubmitThenCallAfterActivation = async (values: NameValueDict<FieldValueType>) => {
    const response = await onAddProfileDetails(values);
    if (response.status === ApiResponseStatus.Success) {
      completeSignup();
    }
    return response;
  };

  const {
    fields,
    isLoading,
    handleChange,
    handleFormSubmit: handleSubmit,
    actionAlert,
  } = useCustomForm({
    initialFields: Object.assign({}, ...initialFields.map((x) => ({ [x.name]: x }))),
    onSubmit: onSubmitThenCallAfterActivation,
  });

  return (
    <CommonModal onDismiss={onDismiss}>
      <CommonModalHeader title="Account Profile" />
      <div className={styles.activated}>
        <ModalHeader
          modalTitle={
            userInfo.status === UserActivationStatus.Active ? 'Account Verification Successful' : ''
          }
        />

        {userInfo.status === UserActivationStatus.Active && (
          <p className="text-center">
            Your account has been verified! Enter in address and phone info
          </p>
        )}
        {userInfo.status !== UserActivationStatus.Active && (
          <p className="text-center">
            Please enter in address and phone info so our platform can better serve you
          </p>
        )}
        <BaseForm
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          actionAlert={actionAlert}
          name="email-activation-client"
        >
          <AutoCompleteAddress handleChange={handleChange} fields={fields} />

          <InputField
            inputProps={{ type: 'tel', pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}' }}
            onChange={handleChange}
            field={fields.phone as TextLikeField}
          />

          <CommonModalFooter>
            <button
              type="submit"
              className={`btn primary red ${isLoading && 'loading'}`}
              disabled={isLoading}
            >
              Complete Signup
            </button>
          </CommonModalFooter>
        </BaseForm>
      </div>
    </CommonModal>
  );
};
