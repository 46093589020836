/* eslint-disable react/no-danger */
import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import TimeAgo from 'timeago-react';

import { IAlert } from '../../../model/Alerts';
import { useSiteStore } from '../../../store/site';

import styles from './NavAlert.module.scss';

type Props = {
  alert: IAlert;
};

export const NavAlert = observer(function NavAlert({ alert }: Props) {
  const site = useSiteStore();
  const router = useRouter();

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    // noinspection JSIgnoredPromiseFromCall
    site.markAsRead(alert.id);

    if (!alert.link) return false;

    site.showAlertsDropdown = false;
    if (alert.link?.startsWith('/')) {
      e.preventDefault();
      router.push(alert.link);
      return false;
    }

    return true;
  };

  return (
    <li className={styles.alert} data-testid={`${alert.id}-alert`}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <a href={`/users/${alert.authorId}`}>
        <div className={styles.avatar}>
          {alert.authorAvatarImage && (
            // eslint-disable-next-line @next/next/no-img-element
            <img height="36" width="36" src={alert.authorAvatarImage} alt="user profile" />
          )}
          {!alert.authorAvatarImage && (
            <span>{alert.authorName.substring(0, 1).toLocaleUpperCase()}</span>
          )}
        </div>
      </a>
      <a href={alert.link} className={classNames(styles.link, 'plain')} onClick={handleClick}>
        <p className={styles.message}>
          <strong data-testid={`${alert.id}-title`}>{alert.title}</strong>
          <span
            data-testid={`${alert.id}-message`}
            dangerouslySetInnerHTML={{ __html: alert.html || '' }}
          />
        </p>
        <small className={styles.timestamp}>
          <TimeAgo datetime={alert.createdAt} locale="en_US" />
          {!alert.isRead && <span className={styles.unread}>(unread)</span>}
        </small>
      </a>
    </li>
  );
});
