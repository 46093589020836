/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes, SyntheticEvent } from 'react';
import { TextLikeField } from '../../types/Field';
import InputError from './InputError';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

interface Props {
  /**
   * Field details
   */
  field: TextLikeField;
  /**
   * Optional handler for when the field value changes
   */
  onChange?: (field: TextLikeField, newValue: any) => void;
  /**
   * Optional handler for key up events
   */
  onKeyUp?: (event: any) => void;

  disabled?: boolean;
  className?: string;

  inputProps?: Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'onKeyUp' | 'className'>;
}

export const TextAreaField: React.FC<Props> = observer(
  ({ field, onChange, onKeyUp, disabled, inputProps, className }) => {
    const onChangeHandler = (event: any) => {
      if (onChange) onChange(field, event.target.value);
    };

    const onKeyUpHandler = (event: SyntheticEvent) => {
      if (onKeyUp) {
        onKeyUp(event);
      }
    };

    const isInvalid = field.isInvalid();
    const errorId = `${field.id}-error`;
    const inputField = (
      <textarea
        name={field.name}
        value={field.value}
        disabled={disabled}
        aria-invalid={isInvalid}
        aria-describedby={errorId}
        onChange={onChangeHandler}
        onKeyUp={onKeyUpHandler}
        placeholder={field.placeholder || ''}
        id={field.id}
        {...inputProps}
      />
    );

    if (field.label) {
      return (
        <div className={classNames('field', className)}>
          <label>
            <span className="input-label-text">{field.label}</span>
            {inputField}
            <InputError id={errorId} error={field.error} />
          </label>
        </div>
      );
    }

    return (
      <div className={classNames('field', className)}>
        {inputField}
        <InputError id={errorId} error={field.error} />
      </div>
    );
  }
);

TextAreaField.displayName = 'TextAreaField';
