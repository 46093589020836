export const isNumber = (value: string): boolean => !Number.isNaN(Number(value));

export const isInteger = (value: string): boolean => Number.isInteger(Number(value));

export const parseNumber = (value: string): number => (isNumber(value) ? Number(value) : NaN);

export const formatUSDAmount = (value: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
