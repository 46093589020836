import * as React from 'react';

import { RoseIcon } from '../../images/RoseIcon';

import commonModalStyles from '../organisms/BaseModal.module.scss';
import styles from './ModalHeader.module.scss';
import { ReactNode } from 'react';

type ModalHeaderProps = {
  modalTitle?: string | ReactNode | ReactNode[];
};

export function ModalHeader({ modalTitle }: ModalHeaderProps) {
  return (
    <div className={styles.modalHeader}>
      <RoseIcon height="47" width="47" />
      <h2 className={commonModalStyles.modalTitle}>{modalTitle}</h2>
    </div>
  );
}
