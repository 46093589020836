import React, { DetailedHTMLProps, FormHTMLAttributes, ReactElement } from 'react';
import type { ActionAlert } from '../../types/ActionAlert';
import classNames from 'classnames';
import styles from './BaseForm.module.scss';
import { FormAlert } from '../molecules/FormAlert';

import commonModalStyles from './BaseModal.module.scss';
import { analytics } from '../../store/analytics';

interface BasicFormProps
  extends DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  /**
   * Optional text for the submit button. Defaults to "Submit"
   */
  buttonLabel?: string | ReactElement | ReactElement[];
  /**
   * Id of the form component. Used for submitting the form from outside this component.
   */
  formId?: string;
  /**
   * If true, will show a button to submit the form.
   */
  hasSubmitControls?: boolean;
  /**
   * Handler for when the form is submitted.
   */
  handleSubmit: (event: React.FormEvent<HTMLFormElement> | undefined) => Promise<void>;
  /**
   * let the user cancel
   */
  onDismiss?: () => void;
  /**
   * Text to be shown after request was successful
   */
  // eslint-disable-next-line react/no-unused-prop-types
  successMessage?: string;
  /**
   * Flag that tells us whether the request is in progress
   */
  isLoading: boolean;
  /**
   * Alert to be shown at the bottom of the form
   */
  actionAlert?: ActionAlert;
  /**
   * Additional classes for styling the submit button
   */
  buttonClasses?: string;
  /**
   * name for analytics and testing
   */
  name: string;
}

export const BaseForm: React.FC<React.PropsWithChildren<BasicFormProps>> = (props) => {
  const {
    buttonLabel = 'Submit',
    name,
    formId,
    hasSubmitControls = false,
    handleSubmit,
    isLoading,
    actionAlert,
    children,
    className,
    onDismiss,
    buttonClasses = '',
    ...formProps
  } = props;

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    analytics.event(`form-submit-${name}`);
    await handleSubmit(event);
  };

  return (
    <form
      className={classNames(styles.form, className)}
      onSubmit={onSubmit}
      noValidate
      id={formId}
      name={name}
      data-testid={`form-${name}`}
      {...formProps}
    >
      {children}
      <FormAlert alert={actionAlert} />
      {hasSubmitControls && (
        <div className={commonModalStyles.buttonRow}>
          {onDismiss && (
            <button
              type="button"
              className="btn secondary"
              disabled={isLoading}
              onClick={onDismiss}
            >
              Cancel
            </button>
          )}
          <button
            type="submit"
            className={classNames('btn primary', isLoading && 'loading', buttonClasses)}
            disabled={isLoading}
          >
            {buttonLabel}
          </button>
        </div>
      )}
    </form>
  );
};

BaseForm.displayName = 'BaseForm';
