import React, { ReactElement } from 'react';
import { Field } from '../../types/Field';

export interface LabeledEditableFieldProps {
  /**
   * Field details
   */
  field: Field;

  children?: ReactElement | ReactElement[];
}

export const FieldWithError: React.FC<React.PropsWithChildren<LabeledEditableFieldProps>> = ({
  field,
  children,
}) => (
  <div className="field">
    {children}
    {field.error && <div className="invalid">{field.error}</div>}
  </div>
);
