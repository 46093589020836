import { observer } from 'mobx-react-lite';

import { CommonModalHeader } from '../../common/CommonModalHeader';

type Props = {
  modalTitle: string;
};

export const ProposalModalHeader: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ modalTitle }) => <CommonModalHeader iconPath="/static/desk-red.svg" title={modalTitle} />
);
