/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { SyntheticEvent, useState } from 'react';

import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import useCustomForm from '../../../../../hooks/useCustomForm';

import { Proposal } from '../../../../../store/Proposal';
import { useUserStore } from '../../../../../store/user';

import { IProposal, RequesterType } from '../../../../../model/Proposal';
import { IProviderInfo, IServiceOption } from '../../../../../model/UserInfo';
import { ISelectOption } from '../../../../../model/helper';

import { EnumField, SelectField, ServicesField, TextLikeField } from '../../../../../types/Field';

import { ProposalCreateFields } from '../../../../../util/fields/ProposalCreateFields';
import { ApiResponse } from '../../../../../util/ApiRequest';
import {
  ModificationServiceName,
  ModificationServices,
  ModificationServicesFilteredProps,
  ModificationServicesValues,
} from '../../../../../util/ServiceConstants';

import { Checkbox } from '../../../../atoms/Checkbox';
import InputError from '../../../../atoms/InputError';
import { TextAreaField } from '../../../../atoms/TextAreaField';
import { StandardSelectFormField } from '../../../../atoms/StandardSelectFormField';

import { ProposalCustomOption } from './ProposalCustomOption';

import { AddCircledIcon } from '../../../../../images/AddCircledIcon';

import styles from './ProposalPage.module.scss';
import proposalStartPageStyles from './ProposalStartPage.module.scss';
import servicesIcons from '../../../../atoms/ServicesIcons.module.scss';
import type { ActionAlert } from '~/types/ActionAlert';

type Props = {
  proposal: Proposal;
  onSubmit: (changedValues: Partial<Proposal>) => Promise<ApiResponse>;
  onFieldChange: () => void;
  provider?: IProviderInfo;
  submitFieldCallback: (callback: any) => void;
  setActionAlert: (actionAlert: ActionAlert | undefined) => void;
};

const pageFieldNames = [
  'requesterType',
  'requesterHealthRole',
  'services',
  'additionalInformation',
];

export const ProposalStartPage: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    proposal,
    onSubmit: callerSubmit,
    onFieldChange,
    provider,
    submitFieldCallback,
    setActionAlert,
  }) => {
    const [adding, setAdding] = useState<boolean>(false);
    const [characterCount, setCharacterCount] = useState<number>(0);
    const additionalInfoMaxCount = 10000;
    const isManager = Boolean(useUserStore().getManagerId());

    const createFields: ProposalCreateFields = new ProposalCreateFields(proposal);
    const initialFields = createFields.getFields(pageFieldNames);
    const [accessibilitySafetyAssessmentChecked, setAccessibilitySafetyAssessmentChecked] =
      useState<boolean>(Proposal.isAssessmentSelected(proposal.services || []));

    const { fields, handleChange, validateThenSubmitFields, actionAlert } = useCustomForm({
      initialFields,
      onSubmit: (values): Promise<ApiResponse> => {
        proposal.mergeForm(values);
        return callerSubmit(values);
      },
      onFieldChange,
    });

    const serviceField = fields.services as ServicesField;
    const modificationsServicesValues = ModificationServices.map((service) => service.value);
    const customValues = new Set<ISelectOption>(
      serviceField?.value?.filter(
        (service) =>
          !modificationsServicesValues.includes(
            service.value.split(' ').join('_').toLowerCase() as ModificationServiceName
          )
      )
    );
    const onAddCustom = (label: string) => {
      const field = fields.services as ServicesField;
      if (!field.value) field.value = [];
      if (!field.value.includes({ label, value: 'other', subServices: [] })) {
        field.value.push({
          label,
          value: 'other',
          subServices: [],
        });
      }
      handleChange(field, field.value);
      setAdding(false);
    };

    /**
     * Sets or removes the selected service from the list of services.
     * @param selectedService - The selected service
     * @returns void
     */
    const updateSelectedServices = (selectedService: ModificationServiceName) => {
      if (!serviceField.value?.length) {
        serviceField.value = [];
        serviceField.value.push(ModificationServicesFilteredProps[selectedService]);
      } else if (serviceField.value?.length) {
        const index = serviceField.value.findIndex(
          (entry: IServiceOption) => entry.value === selectedService
        );

        if (index > -1) {
          serviceField.value.splice(index, 1);
        } else {
          serviceField.value.push(ModificationServicesFilteredProps[selectedService]);
        }
      }
      handleChange(serviceField, serviceField.value);
    };

    const onRemoveCustom = (label: string) => {
      const field = fields.services as EnumField;
      if (!field.value) field.value = [];
      field.value = field.value.filter((o) => o.label !== label);
      handleChange(field, field.value);
    };

    const offers = (name: ModificationServiceName) => {
      if (!provider || !provider.services) return true;
      return provider.services.some((s) => s.value === name);
    };

    // pass function to call when this step is submitted
    submitFieldCallback(validateThenSubmitFields);
    setActionAlert(actionAlert);

    const requesterType = fields.requesterType as SelectField;
    const requesterHealthRole = fields.requesterHealthRole as SelectField;

    if (isManager && !requesterType.value) {
      requesterType.value = RequesterType.Myself;
      handleChange(requesterType, requesterType.value);
    }

    return (
      <>
        {!isManager && (
          <div className={styles.field}>
            <StandardSelectFormField
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleChange(requesterType, event.target.value)
              }
              field={requesterType}
            />
          </div>
        )}

        {!isManager && fields.requesterType.value === RequesterType.Patient && (
          <div className={classNames(styles.field, styles.fieldNotSep)}>
            <StandardSelectFormField
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleChange(requesterHealthRole, event.target.value)
              }
              field={requesterHealthRole}
            />
          </div>
        )}

        <p className={styles.fieldLabelText}>
          Choose which services you want completed. (i.e. <i>I need help with...</i>)
        </p>

        <ul
          className={classNames(
            'reset-list',
            proposalStartPageStyles.services,
            servicesIcons.servicesIconsContainer
          )}
        >
          {ModificationServices.map((service) => {
            if (!offers(service.value)) {
              return null;
            }
            return (
              <li key={service.value} className={proposalStartPageStyles.serviceWrapper}>
                <label className={proposalStartPageStyles.serviceHeader} htmlFor={service.value}>
                  <Checkbox
                    disabled={
                      service.value !== ModificationServicesValues.AccessibilitySafetyAssessment &&
                      accessibilitySafetyAssessmentChecked
                    }
                    id={service.value}
                    key={service.value}
                    name="services"
                    value={service.value}
                    onCheckedChange={() => {
                      updateSelectedServices(service.value as ModificationServiceName);
                      if (
                        service.value === ModificationServicesValues.AccessibilitySafetyAssessment
                      ) {
                        setAccessibilitySafetyAssessmentChecked(
                          !accessibilitySafetyAssessmentChecked
                        );
                      }
                    }}
                    checked={serviceField.value?.some((v) => v.value === service.value)}
                  />
                  <span
                    className={classNames(servicesIcons.servicesIconsContainer, service.iconClass)}
                  >
                    {service.clientLabel}
                  </span>
                </label>
                <ul className={proposalStartPageStyles.servivesSubList}>
                  {service.subServices &&
                    service.subServices.map((sub) => (
                      <li key={sub}>
                        <span>{sub}</span>
                      </li>
                    ))}
                </ul>
              </li>
            );
          })}

          <>
            <li className={proposalStartPageStyles.serviceWrapper}>
              <label className={proposalStartPageStyles.serviceHeader}>
                <span className={classNames(servicesIcons.servicesIconsContainer, 'custom')}>
                  Custom (Add up to 3 custom services)
                </span>
              </label>
              {customValues && (
                <ul className="reset-list">
                  {Array.from(customValues).map((v) => (
                    <li key={v.label}>
                      <ProposalCustomOption
                        onAdd={onAddCustom}
                        onRemove={onRemoveCustom}
                        option={v}
                      />
                    </li>
                  ))}
                </ul>
              )}

              {adding ? (
                <ProposalCustomOption onAdd={onAddCustom} onRemove={onRemoveCustom} />
              ) : (
                <div className="button-row">
                  <button
                    type="button"
                    className="btn secondary blue"
                    onClick={() => setAdding(true)}
                  >
                    <AddCircledIcon /> &nbsp;&nbsp;Add Service
                  </button>
                </div>
              )}
              <InputError error={fields.services.error} />
            </li>
          </>
        </ul>

        <label className={proposalStartPageStyles.textArea}>
          <span id="additonal-info-field-label">Enter additional info about your request</span>
          <span id="character-counter-info" className={proposalStartPageStyles.characterCounter}>
            {characterCount
              ? `You have ${additionalInfoMaxCount - characterCount} characters left.`
              : 'You can enter up to 10,000 characters'}
          </span>
          <TextAreaField
            aria-describedby="additonal-info-field-label character-counter-info"
            field={fields.additionalInformation as TextLikeField}
            onChange={handleChange}
            onKeyUp={(event: SyntheticEvent) => {
              const target = event.target as HTMLTextAreaElement;
              setCharacterCount(target.value.length);
            }}
            className={styles.textArea}
          />
        </label>
      </>
    );
  }
);
