import { AuthStore } from './authStore';
import { CommonModalHeader } from '../../common/CommonModalHeader';
import { ChevronLeftIcon } from '@radix-ui/react-icons';
import React from 'react';

export function AuthStepTitle(props: { auth: AuthStore; label: string }) {
  return (
    <CommonModalHeader
      title={
        <>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button type="button" className="btn icon" onClick={props.auth.goBack}>
            <ChevronLeftIcon width={20} height={20} />
          </button>
          {props.label}
        </>
      }
      showCloseButton={false}
    />
  );
}
