import React, { useEffect, useState } from 'react';
import { ProposalModal } from '../components/templates/modals/proposals/create/ProposalModal';
import { SuccessfullyCreatedProposalModal } from '../components/templates/modals/proposals/create/SuccessfullyCreatedProposalModal';
import { IProviderInfo } from '../model/UserInfo';
import { ApiResponseStatus } from '../util/ApiRequest';
import { Proposal } from '../store/Proposal';
import { ProposalStatus, ProposalType } from '../model/Proposal';
import { UserProfileStore } from './UserProfile/store';
import { observer } from 'mobx-react-lite';
import { useUserStore } from '../store/user';
import { useRouter } from 'next/router';
import { analytics } from '../store/analytics';
import { ModalType, useSiteStore } from '../store/site';

enum ProposalModalTypes {
  CreateProposal = 'CREATE_PROPOSAL',
  SuccessfullyCreatedProposal = 'SUCCESS',
  ActivationNeeded = 'ACTIVATION_NEEDED',
  None = 'NONE',
}

type Props = {
  profileStore?: UserProfileStore;
  onDismiss?: () => void;
};

export const CreateProposal: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ profileStore, onDismiss }) => {
    const [proposalId, setProposalId] = useState<string>();
    const site = useSiteStore();
    const [proposal] = useState<Proposal>(
      new Proposal({
        type: ProposalType.Public,
        status: ProposalStatus.Draft,
      })
    );
    const [modalShown, setModalShown] = useState<ProposalModalTypes>(ProposalModalTypes.None);
    const authContext = useUserStore();
    const router = useRouter();
    const provider =
      profileStore && profileStore.isProvider
        ? (profileStore?.profile as IProviderInfo)
        : undefined;

    const submitProposal = async () => {
      let response = await proposal.create();

      if (provider && response.status === ApiResponseStatus.Success) {
        // we cannot add the connection to the proposal until it's created
        const inviteProviderResponse = await proposal.inviteProvider(provider);

        if (inviteProviderResponse.status === ApiResponseStatus.Success) {
          analytics.event('proposal_created', proposal);
          response = inviteProviderResponse;
          setModalShown(ProposalModalTypes.SuccessfullyCreatedProposal);
          setProposalId(response.data.id);
        }
      } else if (!provider && response.status === ApiResponseStatus.Success) {
        analytics.event('proposal_created', proposal);
        setModalShown(ProposalModalTypes.SuccessfullyCreatedProposal);
        setProposalId(response.data.id);
      }

      return response;
    };

    useEffect(() => {
      if (!authContext.isAuthenticated()) {
        setModalShown(ProposalModalTypes.None);
        site.showModal(ModalType.Login);
      }

      setModalShown(ProposalModalTypes.CreateProposal);
    }, [setModalShown, authContext, site, router.pathname]);

    switch (modalShown) {
      case ProposalModalTypes.CreateProposal:
        return (
          <ProposalModal
            proposal={proposal}
            provider={provider}
            onDismiss={() => {
              setModalShown(ProposalModalTypes.None);
              site.showModal(false);
              if (router.pathname === '/proposals/create') {
                router.push('/');
              }
              if (onDismiss) onDismiss();
            }}
            onSave={submitProposal}
          />
        );
      case ProposalModalTypes.SuccessfullyCreatedProposal:
        return (
          <SuccessfullyCreatedProposalModal
            providerInfo={provider}
            onDismiss={() => {
              setModalShown(ProposalModalTypes.None);
              site.showModal(false);
              router.push(`/proposals/${proposalId}`);
              if (onDismiss) onDismiss();
            }}
          />
        );
      default:
        return <></>;
    }
  }
);

CreateProposal.displayName = 'CreateProposal';
