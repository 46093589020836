import { observer } from 'mobx-react-lite';

import { IUserInfo } from '../../model/UserInfo';

import { Avatar } from '../../components/atoms/Avatar';

import styles from './UserProfileAvatar.module.scss';
import classNames from 'classnames';

type ProfileAvatarProps = {
  className?: string;
  profile: IUserInfo;
};

export const ProfileAvatar = observer(({ className, profile }: ProfileAvatarProps) => (
  <div className={classNames(styles.avatar, className)}>
    <Avatar profile={profile} />
  </div>
));

ProfileAvatar.displayName = 'ProfileAvatar';
