import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { CloseButton } from '../atoms/CloseButton';

import styles from './Notecard.module.scss';

type NotecardProps = {
  children: React.ReactNode;
  customClassNames?: string;
  closeButtonLabel?: string;
  noteType?: 'error' | 'general' | 'info' | 'success' | 'warning';
  onDismiss?: () => void;
};

export const Notecard = observer(
  ({
    children,
    customClassNames,
    closeButtonLabel,
    noteType = 'info',
    onDismiss,
    ...rest
  }: NotecardProps) => (
    <div className={classNames(styles.noteCard, styles[noteType], customClassNames)} {...rest}>
      {children}
      {onDismiss && (
        <CloseButton
          className={styles.notecardCloseButton}
          label={closeButtonLabel || ''}
          onClick={onDismiss}
        />
      )}
    </div>
  )
);
