import React, { useContext, useState } from 'react';
import useApiRequest from '../../../../../hooks/useApiRequest';
import { FieldValueType, TextLikeField } from '../../../../../types/Field';
import { NameValueDict } from '../../../../../types/FormTypes';
import { ApiResponse, postRequest } from '../../../../../util/ApiRequest';
import { BaseModal } from '../../../../organisms/BaseModal';
import { UserFields } from '../../../../../util/fields/UserFields';
import useCustomForm from '../../../../../hooks/useCustomForm';
import { BaseForm } from '../../../../organisms/BaseForm';
import classNames from 'classnames';
import { FieldWithError } from '../../../../molecules/FieldWithError';
import { InputField } from '../../../../atoms/InputField';
import styles from './ForgotPasswordModal.module.scss';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useSiteStore } from '../../../../../store/site';

export interface LoginModalProps {
  /**
   * Title for modal
   */
  title?: string;
}

export const ForgotPasswordModal: React.FC<React.PropsWithChildren<LoginModalProps>> = observer(
  ({ title = 'Forgot Password' }) => {
    const site = useSiteStore();
    const router = useRouter();
    const userFields = new UserFields();
    const [sent, setSent] = useState(false);

    const onDismiss = () => {
      site.showModal(false);
      if (router.pathname.startsWith('/forgot-password')) router.push('/');
    };

    const onSend = async (values: NameValueDict<FieldValueType>) =>
      postRequest('/users/reset/send', values);

    const afterSend = (response: ApiResponse) => {
      setSent(true);
    };

    const { onRequest } = useApiRequest(onSend, afterSend);

    const {
      fields,
      isLoading,
      handleChange,
      handleFormSubmit: handleSubmit,
      actionAlert,
    } = useCustomForm({
      initialFields: {
        email: userFields.emailField,
      },
      onSubmit: onRequest,
    });

    if (sent) {
      return (
        <BaseModal
          onDismiss={onDismiss}
          title={title}
          label
          footerContent={
            <button type="button" className="btn primary" onClick={onDismiss}>
              Okay
            </button>
          }
        >
          <div className="mb-4">
            <p>Please check your email for a link to reset your password.</p>
          </div>
        </BaseModal>
      );
    }

    return (
      <BaseModal onDismiss={onDismiss} title={title} label>
        <BaseForm
          name="forgot-password"
          className={styles.forgot}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          actionAlert={actionAlert}
        >
          <FieldWithError field={fields.email}>
            <InputField
              onChange={handleChange}
              field={fields.email as TextLikeField}
              inputProps={{ autoComplete: 'username' }}
            />
          </FieldWithError>

          <div className="button-row">
            <button type="button" className="btn" onClick={onDismiss}>
              Cancel
            </button>
            <button
              type="submit"
              className={classNames('btn primary', isLoading && 'loading')}
              disabled={isLoading}
            >
              Send Reset
            </button>
          </div>
        </BaseForm>
      </BaseModal>
    );
  }
);
